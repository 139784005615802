import { Dashboardhead } from "../dashboardhead/Dashboardhead";
import { Dashboardfooter } from "../dashboradfooter/Dashboardfooter";
import "./Refund.styles.css";

export function Refund() {
  return (
    <div className="container-fluid  ">
      <Dashboardhead />
      <div className="row">
        <div className="refundimg px-0">
          <img
            src={require("../../../../../asset/img/refund.png")}
            className="img-flud"
          />
        </div>
      </div>
      <div className="row justify-content-center termscontent">
        <div className="text-break col-sm-8">
          <ul>
            <div>Paype REFUND POLICY</div>
            <li>
              <p>
                If you see charges on your credit/debit card for purchases made
                or services availed on the site, but you never created an
                account or signed up, please check with your family members or
                business colleagues authorised to make payments on your behalf,
                to confirm that they haven't initiated the payment. If you're
                still unable to recognise the charge, please report the
                unauthorised purchase within 30 days of the transaction to your
                bank to enable Paype Technologies Pvt Ltd to begin an
                investigation.
              </p>

              <p>
                Once an amount is debited from your credit/debit card or Bank
                account and you the same value is rejected in your ID, there is
                no cancellation or refund allowed for such transactions.
                However, if in a transaction performed by you on the Paype
                Technologies Pvt Ltd Platform, money has been debited from your
                card or bank account and a value is not rejected in your ID
                within 24 hours of the completion of the transaction, then you
                shall inform us by sending an e-mail to our support e-mail
                address mentioned on the ‘Contact Us’ page. Please include in
                the e-mail the following details – value, transaction date and
                request number.
              </p>

              <p>
                If it is found that money was debited to your credit/debit card
                or bank account without credit of the value, then you will be
                refunded the money within 21 working days from the date of
                receipt of your e- mail. All refunds will be credited to the
                original mode of payment.
              </p>

              <p>
                <span>✦</span>All sales/ Money Transfer / Bill Payments /
                Recharges are final with no refund or exchange allowed.
              </p>

              <p>
                <span>✦</span>Customer / Agent is responsible for the mobile
                number, DTH account number, utility consumer number, bank
                account number and details for Purchases / Money Transfer / Bill
                Payments / Recharges and all charges that result from those
                Purchases / Money Transfer / Bill Payments / Recharges. Paype
                Technologies Pvt Ltd is not responsible for any Purchases /
                Money Transfers / Bill Payments / Recharges for an incorrect
                mobile number, DTH account number, utility identification
                number, and bank account number.
              </p>

              <p>
                <span>✦</span>However, if in a transaction performed by a
                Customer on the Site, money has been charged to their card or
                bank account and a Purchase / Money Transfer / Bill Payments / •
                If you see charges on your credit/debit card for purchases made
                or services availed on the site, but you never created an
                account or signed up, please check with your family members or
                business colleagues authorised to make payments on your behalf,
                to confirm that they haven't initiated the payment. If you're
                still unable to recognise the charge, please report the
                unauthorised purchase within 30 days of the transaction to your
                bank to enable Paype Technologies Pvt Ltd to begin an
                investigation. Recharges are not successful within 72 hours of
                their completion of the transaction, then Customer may inform us
                by sending an email to our support email address mentioned on
                the Contact Us page.
              </p>

              <p>
                <span>✦</span>
                Please include in the email the following details - the mobile
                number / DTH account number / Utility consumer number / bank
                account, operator name, Transaction value, Transaction date and
                Order Number. Instant shall investigate the incident and if it
                is found that money was debited to your credit/debit card or
                bank account without credit of the value, then you will be
                refunded the money within 21 working days from the date of
                receipt of your e-mail. All refunds will be credited to the
                original mode of payment.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Dashboardfooter />
    </div>
  );
}
