import "../privacypolicy/Privacy.styles.css";
import { Dashboardhead } from "../dashboardhead/Dashboardhead";
import { Dashboardfooter } from "../dashboradfooter/Dashboardfooter";

export function Privacypolicy() {
  return (
    <div className="container-fluid  ">
      <Dashboardhead />
      <div className="row">
        <div className="privacyimges d-flex justify-content-center">
          <img
            src={require("../../../../../asset/img/Privacy.png")}
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row justify-content-center privacycontent">
        <div className="text-break col-sm-8">
          <ul>
            <div>Paype PRIVACY POLICY</div>
            <li>
              <h5>PRIVACY</h5>

              <p>
                Paype Technologies Pvt Ltd Application is developed by Paype
                Technologies Pvt Ltd, a company incorporated under the Companies
                Act, 1956 with its registered office at, No.278. Periyar Nagar,
                Railway Junction Road, Rajagopalapuram Post, Pudukkottai, Tamil
                Nadu – 622003. This Policy describes how Paype Technologies Pvt
                Ltd and its Affiliates/Entities/Subsidiaries/Associates
                including but not limited to Paype Technologies Pvt Ltd, Paype
                Technologies Pvt Ltd, we, our, us” as the context may require
                collect, store, use and otherwise process your Personal
                Information through Paype Technologies Pvt Ltd website, Paype
                Technologies Pvt Ltd Application, m-site, chatbot, notifications
                or any other medium used by Paype Technologies Pvt Ltd to
                provide its services to you (here in after referred to as the
                “Platform”). By downloading and using Paype Technologies Pvt
                Ltd, visiting the Paype Technologies Pvt Ltd website, providing
                your information or availing our product/services, you expressly
                agree to be bound by this Privacy Policy (“Policy”) and the
                applicable service/product terms and conditions. We value the
                trust you place in us and respect your privacy, maintaining the
                highest standards for secure transactions and protection of your
                personal information.
              </p>

              <p>
                This Privacy Policy is published and shall be construed in
                accordance with the provisions of Indian laws and regulations
                including the Information Technology (Reasonable Security
                Practices and Procedures and Sensitive Personal Data or
                Information) Rules, 2011 under the Information Technology Act,
                2000, the Aadhar Act, 2016 and its Amendments, including the
                Aadhar Regulations; that require publishing of the privacy
                policy for the collection, use, storage, transfer, disclosure of
                Personal Information. Personal Information means and includes
                all information that can be linked to a specific individual and
                also includes Sensitive Personal Information (all Personal
                Information which requires heightened data protection measures
                due to its sensitive and personal nature), both, hereinafter
                referred to as “Personal Information”, excluding any information
                that is freely available or accessible in the public domain.
                Please note, that we do not offer any product/service under our
                Platform outside India. If you do not agree with this Privacy
                Policy, please do not use or access our Platform
              </p>
            </li>

            <li>
              <h5>Information Collection</h5>

              <p>
                We may collect your Personal Information when you use our
                services or Platform or otherwise interact with us during the
                course of our relationship. We collect Personal Information
                which is relevant and absolutely necessary for providing the
                services requested by you and to continually improve the Paype
                Technologies Pvt Ltd Platform.
              </p>

              <p>
                Personal and Sensitive Personal Information collected includes
                but not limited to:
              </p>

              <p>
                <span>✦</span>
                Name, age, gender, photo, address, phone number, e-mail id, your
                contacts and nominee details
              </p>

              <p>
                <span>✦</span>
                PAN card number, KYC-related information such as videos or other
                online/ offline verification documents as mandated by relevant
                regulatory authorities, your business-related information
              </p>

              <p>
                <span>✦</span>
                Aadhaar information including Aadhaar number or Virtual ID for the
                purposes of e-KYC authentication with the Unique Identification
                Authority of India (UIDAI). Note that submission of Aadhaar
                information is not mandatory for e-KYC authentication, you can
                also use other types of information, such as your PAN or GST
                number
              </p>

              <p>
                <span>✦</span>
                OTP sent to you by your bank or Paype Technologies Pvt Ltd
              </p>

              <p>
                <span>✦</span>
                Balance, transaction history and value, bank account details,
                wallet balance, investment details and transactions, service or
                transaction-related communication, and part of your card details
                for the smooth transaction using Paype Technologies Pvt Ltd or
                any of the services
              </p>

              <p>
                <span>✦</span>
                Your device details such as device identifier, internet
                bandwidth, mobile device model, browser plug-ins, and cookies or
                similar technologies that may identify your browser/Paype
                Technologies Pvt Ltd Application and plug-ins, and time spend,
                IP address and location
              </p>

              <p>
                {" "}
                <span>✦</span>
                Information may be collected at various stages of your usage of
                the Paype Technologies Pvt Ltd Platform such as:
              </p>

              <p>
                <span>✦</span>
                Visiting Paype Technologies Pvt Ltd Platform
              </p>

              <p>
                <span>✦</span>
                Registering on Paype Technologies Pvt Ltd Platform as an “User”
                or “Merchant” or any other relationship that may be governed by
                terms and conditions listed on Paype Technologies Pvt Ltd
                Platform
              </p>

              <p>
                <span>✦</span>
                Transacting or attempting to transact on Paype Technologies Pvt
                Ltd Platform
              </p>

              <p>
                <span>✦</span>
                Accessing links, e-mails, chat conversations, feedbacks,
                notifications sent or owned by Paype Technologies Pvt Ltd
                Platform and if you opt to participate in our occasional surveys
              </p>

              <p>
                <span>✦</span>
                Otherwise dealing with any of the Paype Technologies Pvt Ltd
                Affiliates/Entities/Subsidiaries/Associates
              </p>

              <p>
                <span>✦</span>
                While applying for career opportunities with Paype Technologies
                Pvt Ltd We and our service providers or business partners may
                also collect your Personal Information from third parties or
                information made publicly available including but not limited
                to:
              </p>

              <p>
                <span>✦</span>
                Financial history and other information for the purpose of
                verifying and authenticating an investment transaction request
                you place with us to prevent suspicious transactions, or to
                comply with court judgements and bankruptcies, from credit
                reference and fraud prevention agencies.
              </p>

              <p>
                <span>✦</span>
                Vehicle-related information when you opt for vehicle insurance
              </p>

              <p>
                <span>✦</span>
                Your resume, your past employment and educational qualification
                for background checks and verifications, through online or
                offline databases that are otherwise legitimately obtained in
                case you apply for employment opportunities with Paype
                Technologies Pvt Ltd
              </p>

              <p>
                <span>✦</span>
                Your demographic and photo information including but not limited
                to Aadhaar number, address, gender, and date of birth as a
                response received from UIDAI upon successful Aadhaar e-KYC
              </p>
            </li>

            <li>
              <h5>Purpose and Use of Information</h5>
              <p>
                Paype Technologies Pvt Ltd may process your Personal Information
                for the following purposes:
              </p>

              <p>
                <span>✦</span>
                Creation of your account and verification of your identity and
                access privileges
              </p>

              <p>
                <span>✦</span>
                Provide you access to the products and services being offered by
                us, merchants, affiliates, subsidiaries, associates, or business
                partners
              </p>

              <p>
                <span>✦</span>
                To conduct the KYC compliance process as a mandatory
                prerequisite as per the requirements of various regulatory
                bodies, including UIDAI under the Aadhaar Act and its Regulations
              </p>

              <p>
                <span>✦</span>
                To validate, process and/or share your KYC information, nominee
                details with other intermediaries, Regulated Entities (REs) or
                AMCs or financial institutions or with any other service
                providers as may be required
              </p>

              <p>
                <span>✦</span>
                To process payments on your behalf and on your instructions;
                communicate with you for your queries, transactions, and/or any
                other regulatory requirement, etc.
              </p>

              <p>
                <span>✦</span>
                To authenticate a transaction request; validate a standing
                instruction for a Systematic Investment Plan or confirm a
                payment made via the services
              </p>

              <p>
                <span>✦</span>
                Enhancing your user experience in various processes/submission
                of applications/availment of product/service offerings by
                analyzing user behaviour on an aggregated basis
              </p>

              <p>
                <span>✦</span>
                To monitor and review products/services from time to time;
                customize the services to make your experience safer and easier,
                and conducting audits
              </p>

              <p>
                <span>✦</span>
                To allow third parties to contact you for products and services
                availed/requested by you on Paype Technologies Pvt Ltd Platform
                or third-party links
              </p>

              <p>
                <span>✦</span>
                To carry credit checks, screenings or due diligence checks as
                lawfully required by us; detect and protect us against error,
                fraud, money laundering and other criminal activity; enforce our
                terms and conditions
              </p>

              <p>
                <span>✦</span>
                To inform you about online and offline offers, products,
                services, and updates; customizing and improving your experience
                by marketing, presenting advertising, and offering tailored
                products and offers
              </p>

              <p>
                <span>✦</span>
                To resolve disputes; troubleshoot problems; technical support
                and fixing bugs; help promote a safe service
              </p>

              <p>
                <span>✦</span>
                To identify security breaches and attacks; investigating,
                preventing, and taking action on illegal or suspected fraud or
                money laundering activities and conducting forensic audits as
                part of internal or external audit or investigation by Paype
                Technologies Pvt Ltd or government agencies located within India
                or outside the Indian jurisdiction
              </p>

              <p>
                <span>✦</span>
                To meet legal obligations While we may also process your
                Personal Information for other legitimate business cases, we
                ensure to take appropriate steps to minimize the processing to
                the extent possible, making it less intrusive to your privacy.
              </p>
            </li>

            <li>
              <h5>Cookies or Similar Technologies</h5>
              <p>
                We use data collection devices such as "cookies" or similar
                technologies on certain pages of the Platform to help analyze
                our web page flow, measure promotional effectiveness, and
                promote trust and safety. "Cookies" are small files placed on
                your device hard-drive/storage that assist us in providing our
                services. Cookies do not contain any of your Personal
                Information. We offer certain features that are only available
                through the use of a "cookie" or similar technologies. We also
                use cookies to allow you to enter your password less frequently
                during a session. Cookies or similar technologies can also help
                us provide information that is targeted to your interests. Most
                cookies are "session cookies," meaning that they are
                automatically deleted from your device hard-drive/storage at the
                end of a session. You are always free to decline/delete our
                cookies or similar technologies if your browser/device permits,
                although in that case you may not be able to use certain
                features on the Platform and you may be required to re-enter
                your password more frequently during a session. Additionally,
                you may encounter "cookies" or other similar technologies on
                certain pages of the Platform that are placed by third parties.
                We do not control the use of cookies by third parties.
              </p>
            </li>

            <li>
              <h5>Information Sharing and Disclosures</h5>
              <p>
                Your Personal Information is shared as allowed under applicable
                laws, after following due diligence and in line with the
                purposes set out in this Policy.
              </p>
              <p>
                We may share your Personal Information in the course of your
                transaction with different categories of recipients such as
                business partners, service providers, merchants, affiliates,
                associates, subsidiaries, legally recognized authorities,
                regulatory bodies, governmental authorities, financial
                institutions, internal teams such as marketing, security,
                investigation team, etc.
              </p>
              <p>
                Personal Information will be shared, as applicable, on
                need-to-know basis, for the following purposes:
              </p>

              <p>
                <span>✦</span>
                For enabling the provision of the products/services availed by
                you and facilitating the services between you and the service
                provider, as requested
              </p>

              <p>
                <span>✦</span>
                For the Aadhaar authentication process by submitting Aadhaar
                information to Central Identities Data Repository (CIDR) and
                National Securities Depository Limited (NSDL)
              </p>

              <p>
                <span>✦</span>
                For complying with applicable laws as well as meeting the Know
                Your Customer (KYC) requirements as mandated by various
                regulatory bodies, whose regulated service/product you opt
                through our services/Platforms
              </p>

              <p>
                <span>✦</span>
                For completing a payment transaction initiated by you on a
                merchant site, where based on your instructions, the merchant
                requests to fetch your Personal Information from us
              </p>

              <p>
                <span>✦</span>
                For the purpose of processing your financial product
                subscription requests placed with us and ensuring that these
                requests reach the relevant financial institution whose
                service/product you have opted for
              </p>

              <p>
                <span>✦</span>
                If it is required by financial institutions to verify, mitigate,
                or prevent fraud or to manage risk or recover funds in
                accordance with applicable laws/regulations
              </p>

              <p>
                <span>✦</span>
                For services related to communication, marketing, data and
                information storage, transmission, security, analytics, fraud
                detection, risk assessment and research
              </p>

              <p>
                <span>✦</span>
                Enforce our Terms or Privacy Policy; respond to claims that an
                advertisement, posting, or other content violates the rights of
                a third party; or protect the rights, property or personal
                safety of our users or the general public
              </p>

              <p>
                <span>✦</span>
                If required to do so by law or in good faith we believe that
                such disclosure is reasonably necessary to respond to subpoenas,
                court orders, or other legal process
              </p>

              <p>
                <span>✦</span>
                If requested by government authorities for government
                initiatives and benefits
              </p>

              <p>
                <span>✦</span>
                For grievance redressed and resolution of disputes
              </p>
              <p>
                <span>✦</span>
                With the internal investigation department within Paype
                Technologies Pvt Ltd or agencies appointed by Paype Technologies
                Pvt Ltd for investigation purposes located within or outside the
                Indian jurisdiction
              </p>

              <p>
                <span>✦</span>
                Should we (or our assets) plan to merge with, or be acquired by
                any business entity, or re-organization, amalgamation,
                restructuring of our business then with such other business
                entity While the information is shared with third parties as per
                purposes set out in this Policy, processing of your Personal
                Information is governed by their policies. Paype Technologies
                Pvt Ltd ensures stricter or no less stringent privacy protection
                obligations are cast on these third-parties, wherever applicable
                and to the extent possible. However, Paype Technologies Pvt Ltd
                may share Personal Information with third-parties such as
                legally recognized authorities, regulatory bodies, governmental
                authorities, and financial institutions as per purposes set out
                in this Policy or as per applicable laws. We do not accept any
                responsibility or liability for usage of your Personal
                Information by these third parties or their policies.
              </p>
            </li>

            <li>
              <h5>Storage and Retention</h5>
              <p>
                To the extent applicable, we store Personal Information within
                India and retain it in accordance with applicable laws and for a
                period no longer than it is required for the purpose for which
                it was collected. However, we may retain Personal Information
                related to you if we believe it may be necessary to prevent
                fraud or future abuse or if required by law such as in the event
                of the pendency of any legal/regulatory proceeding or receipt of
                any legal and/or regulatory direction to that effect or for
                other legitimate purposes.
              </p>
              <p>
                Once the Personal Information has reached its retention period,
                it shall be deleted in compliance with applicable laws.
              </p>
            </li>

            <li>
              <h5>Reasonable Security Practices</h5>
              <p>
                Paype Technologies Pvt Ltd is an ISO 27001 and PCI-DSS certified
                entity and has deployed technical and physical security measures
                to safeguard user’s Personal Information and Sensitive Personal
                Information. Specifically, in order to safeguard your Aadhar
                information, we have implemented applicable security controls as
                given under and required by the Aadhar Regulations. We
                understand that as effective as our security measures are, no
                security system is impenetrable. Hence, as part of our
                reasonable security practices, we undergo strict internal and
                external reviews to ensure appropriate information security
                encryption or controls are placed for both data in motion and
                data at rest within our network and servers respectively. The
                database is stored on servers secured behind a firewall; access
                to the servers is password-protected and is strictly limited.
              </p>
              <p>
                Further, you are responsible for maintaining the confidentiality
                and security of your login id and password. Please do not share
                your Paype Technologies Pvt Ltd login, password, and OTP details
                with anybody. It shall be your responsibility to intimate us in
                case of any actual or suspected compromise to your Personal
                Information.
              </p>
              <p>
                We have provided multiple levels of security to safeguard the
                Paype Technologies Pvt Ltd Application by login/logout option
                and Paype Technologies Pvt Ltd Application lock feature (“Enable
                Screen Lock”) that can be enabled by you. We have preventive
                controls implemented to ensure you use Paype Technologies Pvt
                Ltd Application on your device and the same login credentials
                cannot be used on different device without any additional
                authentication/OTP.
              </p>
            </li>

            <li>
              <h5>Third-Party Products, Services, or Websites</h5>
              <p>
                When you are availing products and services of service providers
                on Paype Technologies Pvt Ltd Platform, Personal Information may
                be collected by respective service providers and such Personal
                Information shall be governed by their privacy policy. You may
                refer to their privacy policy and terms of service to understand
                how your Personal Information will be handled by such service
                providers.
              </p>
              <p>
                Our services may include links to other websites or applications
                when you visit our Platform. Such websites or applications are
                governed by their respective privacy policies, which are beyond
                our control. Once you leave our servers (you can tell where you
                are by checking the URL in the location bar on your browser or
                on the m-site you are redirected to), use of any Personal
                Information that you provide on these websites or applications
                is governed by the privacy policy of the operator of the
                application/website, you are visiting. That policy may differ
                from ours and you are requested to review those policies or seek
                access to the policies from the domain owner before proceeding
                to use those applications or websites. We do not accept any
                responsibility or liability for usage of your Personal
                Information by these third parties or their policies.
              </p>
            </li>

            <li>
              <h5>Your Consent</h5>
              <p>
                We process your Personal Information with consent. By using the
                Paype Technologies Pvt Ltd Platform or services and/or by
                providing your Personal Information, you consent to the
                processing of your Personal Information by Paype Technologies
                Pvt Ltd in accordance with this Privacy Policy. If you disclose
                to us any Personal Information relating to other people, you
                represent that you have the authority to do so and permit us to
                use the information in accordance with this Privacy Policy.
              </p>
            </li>

            <li>
              <h5>Choice/Opt-out</h5>
              <p>
                We provide all users with the opportunity to opt-out of
                receiving any of our services or non-essential (promotional,
                marketing-related) communications from us, after setting up an
                account. If you want to remove your contact information from all
                our lists and newsletters or discontinue any our services,
                please click on the unsubscribe button on the e-mailers.
              </p>
            </li>

            <li>
              <h5>Personal Information Access/Rectification and Consent</h5>
              <p>
                You can access and review your Personal Information shared by
                you by placing a request with us. In addition, you may at any
                time revoke consent given to us to store your e-KYC information,
                collected as part of the Aadhar-based e-KYC process. Upon such
                revocation, you may lose access to services that were availed on
                the basis of the consent provided. In some case, we may continue
                to retain your information as per the ‘Storage and Retention’
                section of this Policy. To raise any of the above requests, you
                may write to us using the contact information provided under the
                ‘Contact Us’ section of this Policy.
              </p>
              <p>
                In case you wish to delete your account or Personal Information,
                please use the ‘Help’ section of the Paype Technologies Pvt Ltd
                Platform.
              </p>
              <p>
                {" "}
                This is a security measure to ensure that Personal Information
                is not disclosed to any person who does not have a right to
                receive it or is not incorrectly modified or deleted.
              </p>
              <p>
                In case For the above requests, Paype Technologies Pvt Ltd may
                need to request specific information from you to confirm your
                identity and ensure authentication. where you need any further
                information specific to the product/ services that you are
                availing, we request you to read through the Terms and
                Conditions specific to the product/service which is easily
                accessible through the Paype Technologies Pvt Ltd Platform. For
                seeking any further information on the same, you can write to us
                at the details mentioned in the ‘Contact Us’ section of this
                Policy.
              </p>
            </li>

            <li>
              <h5>Children Information</h5>
              <p>
                We do not knowingly solicit or collect Personal Information from
                children under the age of 18 and use of our Platform is
                available only to persons who can form a legally binding
                contract under the Indian Contract Act, 1872. If you are under
                the age of 18 years then you must use the Platform or services
                under the supervision of your parent, legal guardian, or any
                responsible adult.
              </p>
            </li>
            <li>
              <h5>Changes to Policy</h5>
              <p>
                Since our business changes constantly, so will our policies. We
                reserve the right, at our sole discretion, to change, modify,
                add, or remove portions of this Privacy Policy at any time
                without any prior written notice to you. We may, however,
                reasonably Endeavour to notify you of the changes, it is your
                responsibility to review the Privacy Policy periodically for
                updates/changes. Your continued use of our services/Platform,
                following the posting of changes will mean that you accept and
                agree to the revisions. We will never make changes to policies
                in order to make it less protective of Personal Information
                already shared by you.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Dashboardfooter />
    </div>
  );
}
