import "./Dashboard.styles.css";
import { Dashboardfooter } from "../dashboradfooter/Dashboardfooter";
import { Dashboardhead } from "../dashboardhead/Dashboardhead";
import { Apple, Google, Link } from "react-bootstrap-icons";

export function Dashboard() {
  return (
    // <div className='vh-100'>
    <div className="container-fluid bg-light">
      <div className="row">
        <Dashboardhead />

        <div className="two col-sm-12 px-0">
          <div
            id="carouselExampleDark"
            className="carousel carousel-dark slide "
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active " data-bs-interval="3000">
                <img
                  src={require("../../../../../asset/img/globe1.png")}
                  className="scrollimg"
                  alt="..."
                />
                <div className="carousel-caption  d-md-block ">
                  <div className="col-sm-6 imageinnertxt1">
                    <h1>Paype</h1>
                    <p>For cardless transactions in a heartbeat, download.</p>
                    <div className="col-sm-9 ">
                      <div className="row gy-3 justify-content-between">
                        <div className="row justify-content-evenly">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.Paype"
                            className="col-sm-6 "
                            target="blank"
                          >
                            <button type="button" className="img1btn1">
                              <i>
                                <Google />
                              </i>
                              Play store
                            </button>
                          </a>

                          <a href="/" className="col-sm-6 ">
                            <button type="button" className="img1btn1">
                              <i>
                                <Apple size="22" />
                              </i>
                              App store
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item" data-bs-interval="3000">
                <img
                  src={require("../../../../../asset/img/recharge finale1.png")}
                  className="scrollimg"
                  alt="..."
                />
                <div className="carousel-caption  d-md-block ">
                  <div className="col-sm-7 imageinnertxt2 ">
                    <h5>Pay whenever you like, wherever you like </h5>
                    <p className="fs-5">
                      No matter where you are, you can pay.
                    </p>
                    <p className='="fs-5'>pay anyone, anywhere</p>
                  </div>
                </div>
              </div>

              <div className="carousel-item ">
                <img
                  src={require("../../../../../asset/img/sheild secure.png")}
                  className="scrollimg "
                  alt="..."
                />
                <div className="carousel-caption  d-md-block ">
                  <div className="col-sm-6 imageinnertxt3 ">
                    <h1>Paype Security</h1>
                    <p>Paype protects your money with best Security system</p>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>

            <div className="mt-5 carousel-indicators ">
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>

        <div className="three col-sm-12  ">
          <div className="row split mx-0 justify-content-evenly p-2 g-3">
            <div className="col-xl-5 col-12 split1  p-2">
              {/* <div> */}
              <div className="col-8 col-xl-8 ">
                <h2>Aadhaar Enabled Payment Services</h2>
                <p className="px-sm-4 px-0">
                  A bank led model which allows online interoperable financial
                  transactions like Cash Deposit/Withdraw & Mini Statement
                  Enquiry through Aadhar.
                </p>
              </div>
              <div className=" col-4 col-xl-4   d-flex justify-content-center">
                <img
                  src={require("../../../../../asset/img/services/Aadhaar.png")}
                  className="img-fluid p-3"
                  alt="..."
                />
                {/* </div> */}
              </div>
            </div>

            <div className="col-xl-5 col-12 split1  p-2">
              {/* <div className="row justify-content-center"> */}
              <div className="col-8 col-xl-8 ">
                <h2>Domestic Money Transfer</h2>
                <p className="px-sm-4 px-0">
                  Domestic Money Transfer (DMT) is a unique product that can be
                  used to send money instantly to any Bank's account holder
                  within India.
                </p>
              </div>

              <div className="col-xl-4 col-4  d-flex justify-content-center">
                <img
                  src={require("../../../../../asset/img/dashboard/DirectMoneyTransfer.png")}
                  className="img-fluid"
                  alt="..."
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>

        {/* <div className="five col-sm-12 ">
          <h1>WHY Paype ?</h1>
          <h2>
            <span>**</span> One app for all things money <span>**</span>{" "}
          </h2>
          <div className="col-sm-12">
            <div className="row justify-content-center">
              <div className="col-sm-5">
                <p>
                  <img src={require("../../../../../asset/img/star.gif")} />
                  There is one app that can help you with all your money
                  matters.
                </p>

                <p>
                  <img src={require("../../../../../asset/img/star.gif")} />
                  There is an app for everything money-related.
                </p>

                <p>
                  <img src={require("../../../../../asset/img/star.gif")} />
                  On Paype, you can find all of your favourite apps.
                </p>

                <p>
                  <img src={require("../../../../../asset/img/star.gif")} />
                  An app that provides money-related information and services.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="six mx-0 px-0">
          <div className="col-sm-12 box">
            <div className=" box1 ">
              <img
                src={require("../../../../../asset/img/dashboard/Dmt.png")}
                className="slideimg"
                alt="..."
              />
            </div>

            <div className="box2 ">
              <img
                src={require("../../../../../asset/img/dashboard/ticket.png")}
                className="slideimg p-lg-5 p-2"
                alt="..."
              />
              <h5
                className="text-center fw-bold"
                style={{ marginTop: "-30px" }}
              >
                TICKET BOOKINGS
              </h5>
            </div>

            <div className="  box3 ">
              <img
                src={require("../../../../../asset/img/secure mobile555.png")}
                className="slideimg"
                alt="..."
              />
            </div>

            <div className="box4">
              <img
                src={require("../../../../../asset/img/dashboard/Pan2.png")}
                className="slideimg"
                alt="..."
              />
            </div>

            <div className="  box5">
              <img
                src={require("../../../../../asset/img/dashboard/case-management.png")}
                className="slideimg"
                alt="..."
              />
            </div>
          </div>
        </div>

        {/* <div className="eight col-sm-12 px-0">
          <img
            src={require('../../../../../asset/img/Banks.png')}
            className="img-fluid"
            alt="..."
          />
        </div> */}

        <div className="nine col-sm-12">
          <div className="row even justify-content-evenly">
            <div className="col-sm-7 even1 ">
              <img
                src={require("../../../../../asset/img/and.gif")}
                className="img-fluid"
                alt="..."
                style={{ height: "90%" }}
              />
            </div>

            <div className="col-sm-5 even1">
              <p
                className="fw-bold"
                style={{ fontSize: "2.4rem", color: "#06848d" }}
              >
                For Business
              </p>
              <p
                className="mt-4"
                style={{ fontSize: "16px", fontWeight: "lighter" }}
              >
                Provide a holistic approach to financial management
              </p>
              <div className="businesstxt col-sm-11">
                <h5 className="mt-4">Be on Paype</h5>
                <p>
                  Success for businesses, success for people, success for you{" "}
                </p>
              </div>

              <div className="businesstxt col-sm-11">
                <h5 className="mt-4">Accept Online payments at stores</h5>
                <p>It Help your business & everything go cashless</p>
              </div>

              <div className="businesstxt col-sm-11">
                <h5 className="mt-4">It's Possible!</h5>
                <p>My mobile..My bank..My wallet..Transactions Without Cash</p>
              </div>

              <div
                className="businesstxt col-sm-11"
                style={{ borderBottom: "none" }}
              >
                <h5 className="mt-4">Accept Online Payment</h5>
                <p>
                  Go digital and save time & Reliable & High Security while
                  transactions
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="eleven col-sm-12 px-0">
          <div className="col-sm-12 ">
            <img
              src={require("../../../../../asset/img/secure.png")}
              alt="secure"
              className="img-fluid"
            />
          </div>
        </div>

        <Dashboardfooter />
      </div>
    </div>
  );
}
