import "./Dashboardfooter.styles.css";

export function Dashboardfooter() {
  return (
    <div className="twelve col-12 mt-5">
      <div className="row foot mx-0 mt-3 justify-content-center">
        <div className="footimg d-flex justify-content-center  col-12 col-sm-6 col-md-4 col-lg-2 ">
          <img
            src={require("../../../../../asset/img/logo/paypelogo.png")}
            alt="Paype logo"
            className="img-fluid mt-3"
          />
        </div>

        <div className="foot1  col-5 col-sm-6 col-md-4 col-lg-2   p-0">
          <div className=" general ">
            <div className="  justify-content-center">
              <p className="foothead">General</p>
              <a href="/aboutus">
                <p className="foothead1">
                  <span>About us</span>
                </p>
              </a>
              <a href="/contactus">
                <p>
                  <span>Contact us</span>
                </p>
              </a>
              <a href="/charge">
                <p>
                  <span>Schedulde of charge</span>
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="foot1 col-7 col-sm-6 col-md-4 col-lg-2  p-0">
          <div className="legal ">
            <p className="foothead">Legal</p>
            <a href="/termsconditions">
              <p className="foothead1">
                <span>Terms & Conditions</span>
              </p>
            </a>
            <a href="/privacypolicy">
              <p>
                <span>Privacy Policy</span>
              </p>
            </a>
            <a href="/refund">
              <p>
                <span>Refund Policy</span>
              </p>
            </a>
          </div>
        </div>

        <div className="foot1 text-center col-12 col-sm-6 col-md-4 col-lg-2  ">
          <p className="foothead">Connect us</p>

          <div className="row mt-4">
            <ul className="footer-icon">
              <li className="linkedin">
                <a
                  href="https://www.linkedin.com/company/Paypetechnologies/"
                  target="blank"
                >
                  <i className="fa">&#xf08c;</i>
                </a>
              </li>
              <li className="twitter">
                <a
                  href="https://twitter.com/PaypeTech?t=OSwJGDWENIbXqwvpw8LbfQ&s=08"
                  target="blank"
                >
                  <i className="fa">&#xf099;</i>
                </a>
              </li>
              <li className="facebook">
                <a
                  href="https://www.facebook.com/PaypeTechnology"
                  target="blank"
                >
                  <i className="fa">&#xf082;</i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="foot1 text-center  col-6 col-sm-6 col-md-4 col-lg-2">
          <p className="foothead">Download our App</p>

          <div className="row mt-0">
            <ul className="footer-icon">
              <li className="playstore">
                <a
                  href="https://play.google.com/store/apps/details?id=com.Paype"
                  target="blank"
                >
                  {/* <i className="fa fa-google-play "></i> */}
                  <i
                    className="fab fa-google-play"
                    style={{ fontSize: "2em", color: "rgb(83, 83, 83)" }}
                  ></i>
                  <p className="mt-3">Play Store</p>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="foot1 text-center  col-6 col-sm-6 col-md-4 col-lg-2  ">
          <p className="foothead ">Make in India with</p>

          <img
            className="img-flui"
            src="http://i1.wp.com/smepost.com/wp-content/uploads/2015/12/MAKE-IN-INDIA.png?fit=640%2C480"
            style={{ height: "80px" }}
          />
        </div>
      </div>
      <hr />

      <div className="col-lg-11 col-12 mt-3 px-lg-5 px-3 footerdetails">
        <h6>© 2022 Paype Technologies Private Limited.</h6>
        <p>
          Paype is not a bank but a technology platform for digital financial
          services and advisory in partnership with RBI licensed Banks, SEBI
          licensed AMCS and IRDAI licensed Insurers. All funds in the bank
          account are insured as per limits under the RBI's deposit Insurance
          scheme.
        </p>
        <p>Corporate Identity Number: U74994TN2022PTC151114</p>
        <p>
          Never share your password, card number, expiry date, CVV, PIN, OTP or
          other confidential information with anyone even if the person claims
          to be from Paype Technologies.
        </p>
      </div>
    </div>
  );
}
