import "./Dashboardhead.styles.css";
import { List, XCircleFill } from "react-bootstrap-icons";
export function Dashboardhead() {
  return (
    <nav className="navbar navbar-expand-lg  fixed-top  p-0 m-0">
      <div className="container-fluid  px-0 ">
        <a className="head-logo" href="/">
        
        <img
            src={require("../../../../../asset/img/logo/paypelogonav.png")}
            alt="paype logo"
            className="logo-circle"></img>
            
            
         {/* <div className="logo-img "> <img
            src={require("../../../../../asset/img/logo/Paype-P.png")}
            alt="paype logo"
            className="logo-circle"
          />
          <img
            src={require("../../../../../asset/img/logo/Paype text.png")}
            alt="paype logo"
            className="logo-circle "
          /></div> }
          {/* <img
            src={require("../../../../../asset/img/logo/paype text.png")}
            alt="paype logo"
            className="logo-text"
          /> */}
        </a>
        <a
          className="navbar-toggler border-0"
          // style={{ marginRight: '15px' }}
          // type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <i className="fs-1 text-black mx-4">
            <List />
          </i>
        </a>
        <div
          className="offcanvas offcanvas-start-lg "
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header  m-0 p-2 d-flex d-lg-none">
            <a className="head-logo" href="/">
              <img
                src={require("../../../../../asset/img/logo/paypelogop.png")}
                alt="logo"
                className="logo-circle"
              />
              <img
                src={require("../../../../../asset/img/logo/paypelogotext.png")}
                alt="logo"
                className="logo-text"
              />
            </a>
            <a
              href="javascript:void(0) "
              className="text-reset p-0"
              data-bs-dismiss="offcanvas"
              aria-label="close"
            >
              <i className="fs-2">
                <XCircleFill />
              </i>
            </a>
          </div>
          <div className="offcanvas-body p-lg-0">
            <ul className="navbar-nav ">
              <li className="nav-item ">
                <a className="nav-link " aria-current="page" href="/">
                  Home
                </a>
              </li>

              <li className="nav-item ">
                <a className="nav-link " href="/paypeservices">
                  Services
                </a>
              </li>

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  API Solution
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <a href="/rechargeapi" className="dropdown-item">
                      Recharge API
                    </a>
                  </li>
                  <li>
                    <a href="/bbps" className="dropdown-item">
                      BBPS API
                    </a>
                  </li>
                  <li>
                    <a href="/dmtapi" className="dropdown-item">
                      DMT API
                    </a>
                  </li>
                  <li>
                    <a href="/flightbookingapi" className="dropdown-item">
                      Flight Booking API
                    </a>
                  </li>
                  <li>
                    <a href="/aepsapi" className="dropdown-item">
                      AEPS API
                    </a>
                  </li>
                  <li>
                    <a href="/dthapi" className="dropdown-item">
                      DTH Recharge API
                    </a>
                  </li>
                  <li>
                    <a href="/utilitybillapi" className="dropdown-item">
                      Utility Bill Payment API
                    </a>
                  </li>
                </ul>
              </li> */}

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  Commerical loan
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a href="/businessloans" className="dropdown-item">
                      Business loan
                    </a>
                  </li>
                  <li>
                    <a href="/workingcaptialloan" className="dropdown-item  ">
                      Working captial loan
                    </a>
                  </li>
                  <li>
                    <a href="/msme" className="dropdown-item">
                      MSME Loan
                    </a>
                  </li>
                  <li>
                    <a href="/womensloan" className="dropdown-item ">
                      Business loan for womens
                    </a>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="/aboutus">
                  About us
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="/contactus">
                  Contact us
                </a>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  Logins
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a
                      href="https://accounts.zoho.in/signin?servicename=VirtualOffice&portal_id=60013766183&hide_signup=true&hide_title=true&hide_fs=true&serviceurl=https%3A%2F%2Fmail.zoho.in%2F%3Flogin%3Dtrue%26Requested_Domain%3Dpaype.co.in"
                      className="dropdown-item"
                      target="blank"
                    >
                      Employee login
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://utipan.paype.co.in/"
                      // href="https://www.psaonline.utiitsl.com/psaonline/showLogin"
                      className="dropdown-item"
                      target="_blank"
                    >
                      PAN Services Login
                    </a>
                  </li>
                  {/* <li>
                    <a href="/signup" className="dropdown-item">
                      Sign up
                    </a>
                  </li>
                  <li>
                    <a href="/login" className="dropdown-item">
                      Login
                    </a>
                  </li> */}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
