import { Dashboardhead } from "../Dashboardhead";
import "../../dashboardhead/commerical-loans/Commerical.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
// import { Security } from "@material-ui/icons";
// import { GiReceiveMoney } from "@react-icons/all-files/fa/FaBeer";
// import { FaBeer } from 'react-icons/all';
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { HiDocumentSearch } from "react-icons/hi";
import { TbChartInfographic } from "react-icons/tb";

function Businesscard1(props: any) {
  return (
    <div className="row mx-0 pt-5">
      <div className="col-12 col-xl-3">
        <img className="img-fluid " src={props.businessloanimg}></img>
      </div>

      <div className="col-12 col-xl-5 businessloan-content ">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}
function Businesscard2(props: any) {
  return (
    <div className="row mx-0 justify-content-end pt-3">
      <div className="col-12 col-xl-3">
        <img className="img-fluid" src={props.businessloanimg}></img>
      </div>
      <div className="col-12 col-xl-5 businessloan-content">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}

function Fourcard(props: any) {
  return (
    <div className="col-xl-6 col-12 fourcardmain">
      <i className="fourcardicon">{props.fourcardicon}</i>
      <h6 className="fourcardhead">{props.fourcardhead}</h6>
      <p className="fourdcardcontent">{props.fourdcardcontent}</p>
    </div>
  );
}

export function Businessloans() {
  return (
    <div>
      <Dashboardhead />
      <h1 className="col-12 businessloan-head p-2">𝐁𝐔𝐒𝐈𝐍𝐄𝐒𝐒 𝐋𝐎𝐀𝐍𝐒</h1>

      <div className="container businessloan-body  p-0">
        <Businesscard1
          businessloanp1={"Quick Arrangements Of Working Capital Loan"}
          businessloanp2={
            "Get working capital loan arranged in a timely manner via Paype bill discounting services and receive working capital all in just 24-72 hours"
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/businessloan/quick-access.png")}
        />

        <Businesscard2
          businessloanp1={"Collateral-free Working Capital"}
          businessloanp2={
            "Save your assets and get access to working capital loan to solve your working capital needs only using an unpaid invoice as a pseudo-collateral."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/businessloan/collateral-free.png")}
        />

        <Businesscard1
          businessloanp1={
            "Easy Facilitation Of Working Capital Loan At Best Rates"
          }
          businessloanp2={
            "Take your business to a new height at best possible rates by getting a Working Capital loan arranged via Paype Invoice discounting services."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/businessloan/discount-rates.png")}
        />

        <Businesscard2
          businessloanp1={"Off-The-Books Solution"}
          businessloanp2={
            "Paype offers a zero-liability bill discounting service through working capital loan arrangement that doesn’t impact your balance sheet and hence, discounting your unpaid invoices is the smart way to grow your business."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/businessloan/off-the-books.png")}
        />
      </div>

      <div className="container-fluid bg-light mt-5 ">
        <div className="container pt-3 ">
          <h1 className="col-12 text-center " style={{ letterSpacing: "5px" }}>
            𝐖𝐡𝐲 𝐂𝐡𝐨𝐨𝐬𝐞 𝐏𝐚𝐲𝐩𝐞 ?
          </h1>
          <div className="row ">
            <div className=" col-xl-8 col-12">
              <div className="row mx-0">
                <Fourcard
                  fourcardicon={<GiTakeMyMoney />}
                  fourcardhead={"Collateral-Free Working Capital"}
                  fourdcardcontent={
                    "Get working capital loan arranged and solve your working capital issues using your unpaid invoices via bill discounting in just 24-72 hours*"
                  }
                />

                <Fourcard
                  fourcardicon={<HiDocumentSearch />}
                  fourcardhead={"Shorter Cash Cycles"}
                  fourdcardcontent={
                    "Receive timely access to working capital loan via invoice discounting and speed up your cash cycles for business success"
                  }
                />
                <Fourcard
                  fourcardicon={<GiReceiveMoney />}
                  fourcardhead={"Off-Balance-Sheet"}
                  fourdcardcontent={
                    "Paype offers collateral-free invoice discounting services which helps SMEs like yours gain access to working capital loan that doesn’t impact your balance sheet"
                  }
                />

                <Fourcard
                  fourcardicon={<TbChartInfographic />}
                  fourcardhead={"Business Growth"}
                  fourdcardcontent={
                    "Say hello to business growth and expansion with timely access to money"
                  }
                />
              </div>
            </div>

            <div
              className="col-xl-4 col-12 justify-content-center align-items-center d-flex  p-0"
              style={{ flexDirection: "column" }}
            >
              <div className="col-12 ">
                <img
                  className="img-fluid"
                  src={require("../../../../../../asset/img/commerical img/e-invoicing.png")}
                ></img>
              </div>
              <p className="col-12 mt-3 text-center fs-5 fw-bold">
                All Capital Loan Simplified Through Bill Discounting
              </p>
              <div className="col-12  text-center">
                {/* <button className="bg-info fw-bold " type="button" style={{ borderRadius: '12px', border: 'none', width: '130px', padding: '9px' }}>APPLY NOW</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
