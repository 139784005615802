import "../aboutus/About.styles.css";
import { Dashboardhead } from "../dashboardhead/Dashboardhead";
import { Dashboardfooter } from "../dashboradfooter/Dashboardfooter";
export function Aboutus() {
  return (
    <div className="container-fluid mains">
      <Dashboardhead />

      <div className="col-sm-12">
        <div className="row">
          <div className="abotusheadimg px-0">
            <img
              src={require("../../../../../asset/img/aboutus/Aboutus.png")}
            />
          </div>
          <div className="col-sm-7 aboutcontent">
            <div>
              <h2 className="col-sm-9 fw-bold">
                We are a Creative Paype Technology
              </h2>
            </div>
            <div>
              <p className="col-sm-9">
                Paype is the leading service provider fintech company. provide
                Aeps (Aadhar enabled payment system),Micro ATM, MPOS,Railway,
                Flight,Bus Ticket Bookings,Insurances, Micro Loans, Cash
                Collection. We give you exciting offers to make you save money
                and earn more benefits. We are looking forward to make your life
                “Easy and Speedy” in every possible manner. We provide quality
                services at considerably lower price and more value added
                services in forthcoming days. Now you are just a click away from
                the things and services of your desire. We absolutely deliver
                our promise as customer satisfaction, their security of payment
                and privacy is our top most priority. We believe in long term
                relationship and always focus in providing the long term
                solutions to our clients.
              </p>
            </div>
          </div>
          <div className="col-sm-5 px-0">
            <img
              src={require("../../../../../asset/img/aboutus/one.png")}
              className="img-fluid"
              alt="..."
              style={{ height: "100%", paddingTop: "4.5rem" }}
            />
          </div>
        </div>
      </div>

      <div className="col-12 at">
        <div className="row">
          <div className="col-sm-4 about1 ss">
            <div className="row ">
              <div className="col-4  imgback">
                <div className="imgback1 ">
                  <img
                    src={require("../../../../../asset/img/aboutus/quality-results.png")}
                  />
                </div>
              </div>
              <div className="col ss">
                <h5>Industry-best success rates</h5>
                <p className="col-sm-10 ">
                  With Paype intelligent routing & direct bank integrations, we
                  ensure that your customers payments go through every time
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 about1 ss">
            <div className="row ">
              <div className="col-4  imgback">
                <div className="imgback1 ">
                  <img
                    src={require("../../../../../asset/img/aboutus/analytics.png")}
                  />
                </div>
              </div>
              <div className="col ss">
                <h5>India's most widely-used checkout</h5>
                <p className="col-sm-10 ">
                  Over million Indians prefer the Paype Checkout & you can now
                  give your customers the checkout experience they love.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-4 about1 ss">
            <div className="row ">
              <div className="col-4  imgback">
                <div className="imgback1 ">
                  <img
                    src={require("../../../../../asset/img/aboutus/affordable-pricing.png")}
                  />
                </div>
              </div>
              <div className="col ss">
                <h5>Dedicated support</h5>
                <p className="col-sm-10 ">
                  All online merchants and payment gateway users on Paype get
                  dedicated support via a toll-free number and email.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 about1 ss">
            <div className="row ">
              <div className="col-4 imgback">
                <div className="imgback1 ">
                  <img
                    src={require("../../../../../asset/img/aboutus/easy-to-use.png")}
                  />
                </div>
              </div>
              <div className="col  ss">
                <h5>100+ payment sources</h5>
                <p className="col-sm-10 ">
                  Allow your customers to pay way they want with support for
                  debit and credit cards, Netbanking, UPI, Paype Wallet & more
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 about1 ss">
            <div className="row ">
              <div className="col-4  imgback">
                <div className="imgback1 ">
                  <img
                    src={require("../../../../../asset/img/aboutus/free-support.png")}
                  />{" "}
                </div>
              </div>
              <div className="col  ss">
                <h5>Instant activation </h5>
                <p className="col-sm-10 ">
                  Offers 100% online onboarding with minimum documentation to
                  help your business go online with ease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="ourfounders">Our Future Services</div>
        <div className="row founder justify-content-around ">
          <div className="col-sm-3  pox">
            <span></span>
            <div className="row justify-content-center">
              <div className=" founderimg">
                <img
                  src={require("../../../../../asset/img/aboutus/found1.gif")}
                />
              </div>

              <div className="founderdetails ">
                <p>
                  Take advantage of our time-tested platform with 99.9% success
                  rate to help customers send money from anywhere to anywhere
                  within India, real time, 24×7
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-3 pox">
            <span></span>
            <div className="row justify-content-center">
              <div className="founderimg">
                <img
                  src={require("../../../../../asset/img/aboutus/found2.gif")}
                />
              </div>

              <div className="founderdetails ">
                <p>
                  Help customers withdraw DBT, government relief funds and cash
                  from their bank accounts using fingerprint with Aadhar enabled
                  Payment System (AePS)
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-3 pox">
            <span></span>
            <div className="row justify-content-center">
              <div className="founderimg">
                <img
                  src={require("../../../../../asset/img/aboutus/found3.gif")}
                />
              </div>

              <div className=" col-12 founderdetails ">
                <p>
                  Customers can avail of loans against their gold jewellery from
                  any Nearby store. The minimum amount is as low as Rs. 10,000
                  and come at very affordable interest rates
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
