import "../termsconditions/Terms.styles.css";
import { Dashboardhead } from "../dashboardhead/Dashboardhead";
import { Dashboardfooter } from "../dashboradfooter/Dashboardfooter";

export function Termsconditions() {
  return (
    <div className="container-fluid  ">
      <Dashboardhead />
      <div className="row">
        <div className="termsimges px-0">
          <img
            src={require("../../../../../asset/img/terms.png")}
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row justify-content-center termscontent">
        <div className="text-break col-sm-8">
          <ul>
            <div>Paype TERMS & CONDITIONS</div>
            <li>
              <h5>PRIVACY</h5>
              <p>
                Please review Paype Technologies Pvt Ltd Privacy Policy, which
                also governs your visit to this site, to understand our
                practices.
              </p>
            </li>

            <li>
              <h5>ELECTRONIC COMMUNICATIONS</h5>
              <p>
                When you visit the Site or correspond with us via e-mail, you
                are communicating electronically with us. You consent to receive
                communications from us electronically. We will communicate with
                you via email or by posting notices on this Site. You agree that
                all agreements, notices, disclosures and other communication
                that we provide to you electronically satisfy any legal
                requirement that such communications be in writing.
              </p>
            </li>

            <li>
              <h5>COPYRIGHT</h5>
              <p>
                All content included on this Site, such as text, graphics,
                logos, button icons, images, audio clips, data compilations, and
                software, is the property of Paype Technologies Pvt Ltd and its
                content suppliers and protected by India and international
                copyrights. The compilation of all content on this Site is the
                exclusive property of Paype Technologies Pvt Ltd or its
                affiliates and is protected by India and international
                copyrights. The compilation of external content in the Public
                Domain and made available on this Site is the exclusive property
                of third-party content providers and, is subject to India and
                international copyrights. The content has been presented in
                accordance with the necessary permissions and adherence to
                stipulated norms, citation policies and copyright policies of
                these providers. The use of generic information in the public
                domain has been attributed to the source URL and respective
                providers.
              </p>
            </li>

            <li>
              <h5>LICENSE AND SITE ACCESS</h5>
              <p>
                Paype Technologies Pvt Ltd grants you a limited license to
                access and make personal use of this Site and not to download
                (other than page caching) or modify it, or any portion of it,
                except with written consent of Paype Technologies Pvt ltd , as
                may be applicable. Paype Technologies Pvt Ltd grants free
                unrestricted access to public data for your personal use with
                links to download. This is in accordance with the terms of use,
                of the copyrighted material and public site owners. This license
                does not include any resale or commercial use of this Site or
                its contents; any derivative use of this Site or its contents;
                or any sue of data mining, robots, or similar data and
                extraction tools. This Site or any portion of this site
                (including, but not limited to any copyrighted material,
                trademarks, or other proprietary information) may not be
                reproduced, duplicated, copied, sold, resold, visited, or
                otherwise exploited for any commercial purpose without the
                express written consent of Paype Technologies Pvt ltd , as may
                be applicable. You may not frame or utilize framing techniques
                to enclose any trademark, logo, or other proprietary information
                (including images, text, page layout, or form) of Paype
                Technologies Pvt Ltd without express written consent. You may
                not use any Meta tags or any other “hidden text” utilizing Paype
                Technologies Pvt ltd name or trademarks without their express
                consent. Any unauthorised use terminated the permission or
                license granted by Paype Technologies Pvt Ltd. You are granted a
                limited, non-assignable, revocable, and non-exclusive right to
                create a hyperlink to the home page of Paype Technologies Pvt
                Ltd as long as the hyperlink does not portray Paype Technologies
                Pvt Ltd and its products, solutions and or services in a false,
                misleading, derogatory, or otherwise offensive manner. You may
                not use any logo or other proprietary graphic or trademark of
                Paype as part of the link without their express written
                permission.
              </p>
            </li>

            <li>
              <h5>YOUR ACTIVITY ON THE Paype TECHNOLOGIES Pvt LTD SITE</h5>
              <p>
                Paype Technologies Pvt ltd Site is intended for the use of
                individuals of all ages. However, if you are a minor, i.e. a
                person under the age of 18 years, you are recommended to visit
                the Site with the assistance of a parent or guardian and Paype
                Technologies Pvt Ltd reserves the right to edit content in its
                sole discretion. You must not use the Site in any way that
                causes, or is likely to cause, the Site or access to it to be
                interrupted, damaged or impaired in any way. You understand that
                you and not Paype Technologies Pvt Ltd, are responsible for all
                electronic communications and content send from your computer to
                us and you must use the Site for lawful purposes only and only
                in accordance with the applicable law. You MUST NOT use the site
                for any of the following activities:
              </p>

              <p>
                <span>✦</span> For fraudulent purposes, or in connection with a
                criminal offence or other lawful activity.
              </p>

              <p>
                <span>✦</span>To send, use or reuse any material that is
                illegal, offensive, (including but not limited to material that
                is sexually explicit or which promotes racism, bigotry, hatred
                or physical harm), abusive, harassing, misleading, indecent,
                defamatory, disparaging, obscene or menacing; or in breach of
                copyright, trademark, confidentiality, privacy or any other
                proprietary information or right; or is otherwise injurious to
                third parties; or objectionable or otherwise unlawful in any
                manner whatsoever; or which consists of or contains software
                viruses, political campaigning, commercial solicitation, chain
                letters, mass mailing or any “spam" and.
              </p>

              <p>
                <span>✦</span>
                To cause annoyance, inconvenience or needless anxiety, but not
                limited to “indecent representation of women” within the meaning
                of the Indecent Representation of Women (Prohibition) Act, 1986.
              </p>
            </li>

            <li>
              <h5>YOUR LICENSE</h5>
              <p>
                If you submit material, and unless we indicate otherwise, you
                grant Paype Technologies Pvt Ltd a nonexclusive, royalty-free,
                irrevocable, perpetual and fully sub-licensable right to use,
                reproduce, modify, adapt, publish, create derivative works from,
                distribute, and display such content throughout the world in any
                media for as long as you are permitted to grant the said license
                under the applicable law. You grant Paype Technologies Pvt Ltd
                the right to use the name that you submit in connection with
                such content if Paype Technologies Pvt Ltd were to choose. You
                represent and warrant that you submit in connection with such
                content if Paype Technologies Pvt Ltd were to choose. You
                represent and warrant that you own or otherwise control all of
                the rights to the content or material that you post or submit or
                that you otherwise provide on or through the Site; that the
                content is accurate; that the content is lawful; that use of the
                content does not violate this policy and will not cause injury
                to any person or entity; that you will indemnify Paype
                Technologies Pvt Ltd for all claims arising from content you
                provide. Paype Technologies Pvt Ltd has the right, but NOT the
                obligation to monitor and edit or remove any activity or
                content. Paype Technologies Pvt Ltd takes NO responsibility and
                assumes NO liability for any content submitted by you or any
                third party.
              </p>
            </li>

            <li>
              <h5>COPYRIGHT COMPLAINTS</h5>
              <p>
                Paype Technologies Pvt Ltd respects the intellectual property of
                others and makes diligent efforts to take proper written
                permission, cite authors’ creative works and provide URL
                hyperlinks to source documents. Paype Technologies Pvt ltd will
                take immediate action to correct citation errors and attribute
                works of others in accordance with accepted citation formats. If
                you believe that your work has been represented in a way that
                constitutes an infringement of your intellectual property,
                please follow our Trademark and Copyright policy.
              </p>
            </li>

            <li>
              <h5>SERVICES DESCRIPTION</h5>
              <p>
                Paype Technologies Pvt Ltd strives to present facts and contents
                as accurately as possible with proper citations. However, Paype
                Technologies Pvt Ltd does not warrant that descriptions of or
                other content of this Site are accurate, complete, reliable,
                current, or free of errors. Further, your access to the Site may
                also be occasionally suspended or restricted to allow for
                hardware and software upgrades, repairs and maintenance, or the
                introduction of new solutions and services, at any time without
                prior notice. Paype Technologies Pvt Ltd will attempt to limit
                the frequency and duration of any such suspension or
                restriction. Paype Technologies Pvt Ltd provides links to other
                sites over which it has not to control.Paype Technologies Pvt
                Ltd is NOT responsible for the availability of such external
                sites or resources, does NOT endorse and is NOT responsible for
                any content, advertising, products or other material on or made
                available from such sites or resources.
              </p>
            </li>

            <li>
              <h5>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h5>
              <p>
                This site is provided by Paype Technologies Pvt Ltd on an “as
                is” and “as available” basis. Paype Technologies Pvt Ltd makes
                no representations or warranties of any kind express or implied,
                as to the operation, content, materials, or products included on
                this site. You expressly agree that your use of this site is at
                your sole risk. Paype Technologies Pvt Ltd reserves the right to
                withdraw or delete any information from this site at any time at
                its discretion. To the full extent permissible by the applicable
                law, Paype Technologies Pvt Ltd disclaims all warranties,
                express or implied, including, but not limited to, implied
                warranties of merchantability and fitness for a particular
                purpose. Paype Technologies does not warrant that this site, its
                servers, or e-mail sent from Paype Technologies Pvt Ltd are free
                of viruses or other harmful components. Paype Technologies Pvt
                Ltd will not be liable for any damages of any kind arising from
                the use of this site, including, but not limited to direct,
                indirect, incidental, punitive, and consequential damages.
              </p>
            </li>

            <li>
              <h5>APPLICABLE LAW</h5>
              <p>
                By visiting this Site, you agree that the laws of India will
                govern these Terms of Use and any dispute of any sort that might
                arise between you and Paype Technologies Pvt Ltd.
              </p>
            </li>

            <li>
              <h5>DISPUTES</h5>
              <p>
                Any dispute relating in any way to your visit to this Site shall
                be submitted to the exclusive jurisdiction of the courts at
                Madurai -Tamilnadu, India.
              </p>
            </li>

            <li>
              <h5>SITE POLICIES, MODIFICATION AND SEVERABILITY</h5>
              <p>
                Please review our other policies on this Site. These policies
                also govern your visit to the Site. We reserve the right to make
                changes to our Site, Policies and these Terms of Use at any
                time. If any of these conditions shall be deemed invalid, void,
                or for any reason unenforceable, that condition shall be deemed
                severable and shall not affect the validity and enforceability
                of any remaining condition.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Dashboardfooter />
    </div>
  );
}
