import { Dashboardhead } from "../Dashboardhead";
import "../../dashboardhead/commerical-loans/Commerical.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
// import { Security } from "@material-ui/icons";
// import { GiReceiveMoney } from "@react-icons/all-files/fa/FaBeer";
// import { FaBeer } from 'react-icons/all';
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { HiDocumentSearch } from "react-icons/hi";
import { TbChartInfographic } from "react-icons/tb";

function Businesscard1(props: any) {
  return (
    <div className="row mx-0 pt-5">
      <div className="col-12 col-xl-3">
        <img className="img-fluid " src={props.businessloanimg}></img>
      </div>

      <div className="col-12 col-xl-5 businessloan-content ">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}
function Businesscard2(props: any) {
  return (
    <div className="row mx-0 justify-content-end pt-3">
      <div className="col-12 col-xl-3">
        <img className="img-fluid" src={props.businessloanimg}></img>
      </div>
      <div className="col-12 col-xl-5 businessloan-content">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}

function Fourcard(props: any) {
  return (
    <div className="col-xl-6 col-12 fourcardmain">
      <i className="fourcardicon">{props.fourcardicon}</i>
      <h6 className="fourcardhead">{props.fourcardhead}</h6>
      <p className="fourdcardcontent">{props.fourdcardcontent}</p>
    </div>
  );
}

export function Workingcaptialloan() {
  return (
    <div>
      <Dashboardhead />
      <h1 className="col-12 businessloan-head p-2">𝐖𝐎𝐑𝐊𝐈𝐍𝐆 𝐂𝐀𝐏𝐓𝐈𝐀𝐋 𝐋𝐎𝐀𝐍</h1>

      <div className="container businessloan-body  p-0">
        <Businesscard1
          businessloanp1={"Paype offers a customized working capital loan"}
          businessloanp2={
            "For small and medium enterprises to meet their working capital requirements. A working capital loan helps you in running everyday operations."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/workingcaptial/1st.png")}
        />

        <Businesscard2
          businessloanp1={"Top Features of a Paype working capital"}
          businessloanp2={
            "Save your assets and get access to working capital loan to solve your working capital needs only using an unpaid invoice as a pseudo-collateral."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/workingcaptial/3rd.png")}
        />

        <Businesscard1
          businessloanp1={"Superfast processing"}
          businessloanp2={
            "We process all loan applications within 72 hours on an average.Completely online processing There is no need to visit physically or wait in long queues. The application is very simple & is processed online."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/workingcaptial/4th.png")}
        />

        <Businesscard2
          businessloanp1={"Benefits of Working Capital Loan by Paype"}
          businessloanp2={
            "Paype t is a non-banking financial company. NBFCs offer several benefits over traditional private and public financiers."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/workingcaptial/3rd 2.png")}
        />
      </div>

      <div className="container-fluid bg-light mt-5 ">
        <div className="container pt-3 ">
          <h1 className="col-12 text-center " style={{ letterSpacing: "5px" }}>
            𝐖𝐡𝐲 𝐂𝐡𝐨𝐨𝐬𝐞 𝐏𝐚𝐲𝐩𝐞 ?
          </h1>
          <div className="row ">
            <div className=" col-xl-8 col-12">
              <div className="row mx-0">
                <Fourcard
                  fourcardicon={<GiTakeMyMoney />}
                  fourcardhead={"Collateral-Free Working Capital"}
                  fourdcardcontent={
                    "Get working capital loan arranged and solve your working capital issues using your unpaid invoices via bill discounting in just 24-72 hours*"
                  }
                />

                <Fourcard
                  fourcardicon={<HiDocumentSearch />}
                  fourcardhead={"Shorter Cash Cycles"}
                  fourdcardcontent={
                    "Receive timely access to working capital loan via invoice discounting and speed up your cash cycles for business success"
                  }
                />
                <Fourcard
                  fourcardicon={<GiReceiveMoney />}
                  fourcardhead={"Off-Balance-Sheet"}
                  fourdcardcontent={
                    "Paype offers collateral-free invoice discounting services which helps SMEs like yours gain access to working capital loan that doesn’t impact your balance sheet"
                  }
                />

                <Fourcard
                  fourcardicon={<TbChartInfographic />}
                  fourcardhead={"Business Growth"}
                  fourdcardcontent={
                    "Say hello to business growth and expansion with timely access to money"
                  }
                />
              </div>
            </div>

            <div
              className="col-xl-4 col-12 justify-content-center align-items-center d-flex  p-0"
              style={{ flexDirection: "column" }}
            >
              <div className="col-12 ">
                <img
                  className="img-fluid"
                  src={require("../../../../../../asset/img/commerical img/e-invoicing.png")}
                ></img>
              </div>
              <p className="col-12 mt-3 text-center fs-5 fw-bold">
                All Capital Loan Simplified Through Bill Discounting
              </p>
              <div className="col-12  text-center">
                {/* <button className="bg-info fw-bold" type="button" style={{ borderRadius: '12px', border: 'none', width: '130px', padding: '9px' }}>APPLY NOW</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
