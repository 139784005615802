import { LockFill, PersonFill } from 'react-bootstrap-icons'
import '../../dashboardhead/signup/Signup.styles.css'
import { Dashboardhead } from '../Dashboardhead'
import { Dashboardfooter } from '../../dashboradfooter/Dashboardfooter'

function Login() {
  return (
    <>
      <Dashboardhead />
      <div className="signup-body p-2">
        <form className="col-md-10 col-lg-8  col-12 form-body p-md-5">
          <div className="row mx-0 align-items-center">
            <div className="col-sm-5 form-img text-center">
              <img
                className="img-fluid"
                src={require('../../../../../../asset/img/login/signin-image.jpg')}
              ></img>
            </div>

            <div className="col-sm-7 col-12 form-content ">
              <h2 className="mb-3">𝐋𝐎𝐆𝐈𝐍</h2>

              <div className=" col-md-7 col-11 input-div">
                <i>
                  <PersonFill />
                </i>
                <input
                  className="input-field"
                  placeholder="name , email , number"
                  type="text"
                />
                <span className="bar"></span>
              </div>

              <div className=" col-md-7 col-11 input-div">
                <i>
                  <LockFill />
                </i>
                <input
                  className="input-field"
                  placeholder="Password"
                  type="password"
                />
                <span className="bar"></span>
              </div>

              <div className="col-12 mt-5 d-flex justify-content-center">
                <button className="submit-btn" type="submit">
                  Submit
                </button>
              </div>
              <p className="mt-4  text-black">I am already a member</p>
              <a className="text-decoration-none" href="/signup">
                Sign up
              </a>
            </div>
          </div>
        </form>
      </div>
      <Dashboardfooter />
    </>
  )
}
export default Login
