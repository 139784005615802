import { EnvelopeFill, PhoneFill, TelephoneFill } from "react-bootstrap-icons";
import "../contactus/Contactus.styles.css";
import { Dashboardhead } from "../dashboardhead/Dashboardhead";
import { Dashboardfooter } from "../dashboradfooter/Dashboardfooter";

export function Contactus() {
  return (
    <div className="container-fluid px-0">
      <Dashboardhead />

      <div className="contactusimg ">
        <img src={require("../../../../../asset/img/contactus/contact.png")} />
      </div>
      <div className="container">
        <div className="col-sm-6 customerdetails">
          <h4>Customer Support Details</h4>
          <p className="col-8 fw-light" style={{ fontSize: "16px" }}>
            Ask us anything and we'll help you find the answers. Response time:
            within 24 hours
          </p>
        </div>

        <div className="col-sm-12 helpcontent ">
          <div className="row  ">
            <div className="col-sm-4 ">
              <h5>Customer Care Team</h5>
              <p>
                Paype Customer Care team will acknowledge the complaint with a
                ticket number Response/Resolution TAT – Please refer to the
                product-wise timeline for 1st level resolution as enclosed
              </p>

              <p>
                If a case needs extra time, Paype customer care team will inform
                the same along with reasons to the customer.
              </p>

              <div>
                <a href="#">support@Paype.co.in</a>
              </div>
            </div>
            <div className="col-sm-4 ">
              <h5>Grievance Redressal Team</h5>
              <p>
                For any unauthorized/fraudulent transaction reporting, the
                regulatory authority or customer can skip previous levels and
                directly send a mail to the Grievance Escalation Team.
              </p>
              <div className="fw-light">
                <span className="fw-bold">Note:</span> Calls to the Grievance
                Redressal team shall be attended on (working days) Monday to
                Friday between 10.30 A.M to 6.30 P.M Response/Resolution TAT-5
                Working Days
              </div>
              <div>04322-227191</div>
              <div className="mt-1">
                <a href="#">grievances@Paype.co.in</a>
              </div>
            </div>
            <div className="col-sm-4">
              <h5>Nodal Officer</h5>
              <p>
                <span className="fw-bold"> Please Note:</span>
                <br />
                <span className="fw-bold"> a.</span>span Customers are advised
                to follow the hierarchy of the redressal matrix. Skipping levels
                in the redressal matrix may lead to failure in acknowledgement
                and resolution of grievances.
                <br />
                <span className="fw-bold">b.</span> Customers are strictly
                advised to refrain from reporting disputes on social media
                platforms for security purposes.
              </p>

              <div>
                <a href="#">escalation.nodalofficer@Paype.co.in</a>
              </div>
            </div>
            <div className="col-sm-4 mt-5">
              <div className="row">
                <div className="col-sm-2 p-2 ">
                  <TelephoneFill size={30} style={{ color: "red" }} />{" "}
                </div>
                <div className="col-sm-10 ">
                  <h6>Contact us</h6>
                  <div className="pt-2">
                    04322-227191
                    <br />
                    04322-260191
                    <br />
                    04322-260192
                    <br />
                    04322-260193
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 mt-5">
              <div className="row">
                <div className="col-sm-2 p-2 ">
                  <EnvelopeFill size={30} style={{ color: "red" }} />{" "}
                </div>
                <div className="col-sm-10 ">
                  <h6>Email us</h6>
                  <div>
                    {" "}
                    <a href="#">admin@Paype.co.in</a>
                  </div>
                  <div>
                    {" "}
                    <a href="#">info@Paype.co.in</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5" />

        <div className="row contactaddress">
          <div className="col-sm-4  ">
            <ul>
              <h2>Registered Address</h2>
              <li className="mt-4">No.278. Periyar Nagar,</li>
              <li>Railway Junction Road,</li>
              <li>Rajagopalapuram Post,</li>
              <li>Pudukkottai – 622003,</li>
              <li>Tamil Nadu.</li>
              <li>+91 9944857191</li>
              <li>04322- 450 915</li>
            </ul>
          </div>
          <div className="col-sm-4 ">
            <ul>
              <h2>Branch Office</h2>
              <li className="mt-4">No. 11/4, Pooja Garden,</li>
              <li>Kalapatti Main Road,</li>
              <li>Civil Aerodrome Post,</li>
              <li>Coimbatore – 641014,</li>
              <li>Tamil Nadu.</li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <Dashboardfooter />
    </div>
  );
}
