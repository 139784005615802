import { Dashboardhead } from "../Dashboardhead";
import "./Api.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
export function Bbps() {
  return (
    <div className="bg-light apisolutionhead">
      <Dashboardhead />

      <div className="row mx-0 bg-white">
        <div className="col-md-8 col-12 top-p-tag">
          <div className="p-5">
            <h2 className="text-center">BBPS API</h2>
            <p>
              BBPS (Bharat Bill payment system) is a one-stop alternative for
              all bills providing an interoperable and easy to get"Anytime any
              location" charge payment service to all customers across India
              with certainty, reliability, and safety of transactions. BBPS an
              innovative way to cover all utility bills at one stage: BBPS
              system allows multiple modes of payment and provides instant
              confirmation of payment through an SMS or receipt. BBPS system
              takes different classes of bills like power, telecom, DTH, gas,
              water bills, etc. via a single window. BBPS empowers every citizen
              to cover their bills under the same window.
            </p>
            <p>
              Paype Recharge has the very first of its kind bill. Bringing a
              revolution in India's bill payment market, BBPS empowers every
              citizen to pay their bills below the exact same window.
            </p>
            <p>
              We're the prominent developer of BBPS Software and additionally
              integrating API to get Bill payment biller of BBPS. BBPS is
              regulated by NPCI (National Payment Corporation of India) which
              comprises all sort of bill payment solutions such as water,
              electricity, insurance, Gas, landline, postpaid and other bill
              payment services. The client who is already running bill payment
              services may add electricity bill payment API, BBPS bill payment
              API, water bill payment, gas bill payment API, postpaid charge
              payment API, and insurance bill payment API under Bharat bill
              payment API services.
            </p>
          </div>
        </div>

        <div className="col-md-4 col-12  d-flex align-items-center justify-content-center">
          <img
            className="img-fluid rounded sideimg"
            src={require("../../../../../../asset/img/apisolution/BBPS.png")}
          ></img>
        </div>

        <div className="col-12 bottom-p-tag" style={{ marginTop: "-100px" }}>
          <div className="p-5">
            <p>
              Presently, there are just 5 utilities that are allowed on this
              stage, namely - Electricity, Gas, Water, DTH and Telecom. RBL
              Bank's BBPS suite makes it possible for any aggregator from the
              market to join directly with the BBPS community of India. The
              product not only enables the group of bills through internet
              Payment Gateway established platforms but also through an
              extensive network of bodily merchants and agents.
            </p>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
