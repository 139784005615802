import React from "react";
import { Routes, Route } from "react-router-dom";

import { Termsconditions } from "../view/screens/dashboard/maindashboard/termsconditions/Termsconditions";
import { Privacypolicy } from "../view/screens/dashboard/maindashboard/privacypolicy/Privacypolicy";
import { Aboutus } from "../view/screens/dashboard/maindashboard/aboutus/Aboutus";
import { Contactus } from "../view/screens/dashboard/maindashboard/contactus/Contactus";
import { Dashboardhead } from "../view/screens/dashboard/maindashboard/dashboardhead/Dashboardhead";
import { Dashboardfooter } from "../view/screens/dashboard/maindashboard/dashboradfooter/Dashboardfooter";

import { Dashboard } from "../view/screens/dashboard/maindashboard/dashboard/Dashboard";
import { Refund } from "../view/screens/dashboard/maindashboard/refundpolicy/Refund";
import { Rechargeapi } from "../view/screens/dashboard/maindashboard/dashboardhead/api-solution/Rechargeapi";
import { Bbps } from "../view/screens/dashboard/maindashboard/dashboardhead/api-solution/Bbps";
import { DMTAPI } from "../view/screens/dashboard/maindashboard/dashboardhead/api-solution/Dmt";
import { Flightbookingapi } from "../view/screens/dashboard/maindashboard/dashboardhead/api-solution/Flightbookingapi";
import { Aepsapi } from "../view/screens/dashboard/maindashboard/dashboardhead/api-solution/Aepsapi";
import { Utilitybillapi } from "../view/screens/dashboard/maindashboard/dashboardhead/api-solution/Utlitybillpayentapi";
import { Dthapi } from "../view/screens/dashboard/maindashboard/dashboardhead/api-solution/Dthrechargeapi";
import { Businessloans } from "../view/screens/dashboard/maindashboard/dashboardhead/commerical-loans/Businessloans";
import { Womensloan } from "../view/screens/dashboard/maindashboard/dashboardhead/commerical-loans/womensloan";
import { Msme } from "../view/screens/dashboard/maindashboard/dashboardhead/commerical-loans/Msme";
import { Workingcaptialloan } from "../view/screens/dashboard/maindashboard/dashboardhead/commerical-loans/Workingcaptial";
import Signup from "../view/screens/dashboard/maindashboard/dashboardhead/signup/Signup";
import Login from "../view/screens/dashboard/maindashboard/dashboardhead/login/Login";
import { Charge } from "../view/screens/dashboard/maindashboard/dashboradfooter/charge/Charge";
import { Paypeservices } from "../view/screens/dashboard/maindashboard/paypeservices/Paypeservices";

function MainNavigator() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboardhead" element={<Dashboardhead />} />
        <Route path="/dashboardfooter" element={<Dashboardfooter />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/Paypeservices" element={<Paypeservices />} />
        <Route path="/rechargeapi" element={<Rechargeapi />} />
        <Route path="/bbps" element={<Bbps />} />
        <Route path="/dmtapi" element={<DMTAPI />} />
        <Route path="/flightbookingapi" element={<Flightbookingapi />} />
        <Route path="/aepsapi" element={<Aepsapi />} />
        <Route path="/dthapi" element={<Dthapi />} />
        <Route path="/utilitybillapi" element={<Utilitybillapi />} />
        <Route path="/businessloans" element={<Businessloans />} />
        <Route path="/womensloan" element={<Womensloan />} />
        <Route path="/msme" element={<Msme />} />
        <Route path="/workingcaptialloan" element={<Workingcaptialloan />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsconditions" element={<Termsconditions />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/signup" element={<Signup />} />{" "}
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/charge" element={<Charge />} />
      </Routes>
    </div>
  );
}

export default MainNavigator;
