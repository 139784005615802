import '../../../../../screens/dashboard/maindashboard/dashboardhead/signup/Signup.styles.css'
import {
  EnvelopeFill,
  LockFill,
  PersonFill,
  PhoneFill,
} from 'react-bootstrap-icons'

import { Dashboardhead } from '../Dashboardhead'
import { Dashboardfooter } from '../../dashboradfooter/Dashboardfooter'

function Signup() {
  return (
    <>
      <Dashboardhead />
      <div className="signup-body p-2">
        <form className="col-md-10 col-lg-8  col-12 form-body  ">
          <div className="row mx-0 align-items-center ">
            <div className="col-sm-7 col-12 form-content ">
              {/* <div className=" col-10"> */}
              <h2>𝐒𝐈𝐆𝐍 𝐔𝐏</h2>

              <div className=" col-md-8 col-11 input-div">
                <i>
                  <PersonFill />
                </i>
                <input
                  className="input-field"
                  placeholder="First Name"
                  type="text"
                />
                <span className="bar"></span>
              </div>

              <div className=" col-md-8 col-11 input-div">
                <i>
                  <EnvelopeFill />
                </i>
                <input
                  className="input-field"
                  placeholder="Email"
                  type="email"
                />
                <span className="bar"></span>
              </div>

              <div className=" col-md-8 col-11 input-div">
                <i>
                  <PhoneFill />
                </i>
                <input
                  className="input-field"
                  placeholder="Phone Number"
                  type="number"
                />
                <span className="bar"></span>
              </div>

              <div className=" col-md-8 col-11 input-div">
                <i>
                  <LockFill />
                </i>
                <input
                  className="input-field"
                  placeholder="Password"
                  type="password"
                />
                <span className="bar"></span>
              </div>

              <div className="col-12 mt-5 d-flex justify-content-center">
                <button className="submit-btn" type="submit">
                  Submit
                </button>
              </div>
              {/* </div> */}
            </div>

            <div className="col-sm-5 form-img text-center">
              <img
                className="img-fluid"
                src={require('../../../../../../asset/img/login/signup-image.jpg')}
              ></img>
              <p className="mt-4">I am already a member</p>
              <a className="text-decoration-none" href="/login">
                Sign in
              </a>
            </div>
          </div>
        </form>
      </div>
      <Dashboardfooter />
    </>
  )
}
export default Signup
