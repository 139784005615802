import { Dashboardhead } from "../Dashboardhead";
import "./Api.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
export function Utilitybillapi() {
  return (
    <div className="bg-light apisolutionhead">
      <Dashboardhead />

      <div className="row mx-0 bg-white">
        <div className="col-md-8 col-12 top-p-tag">
          <div className="p-5">
            <h2 className="text-center">UTILITY BILL PAYMENT API</h2>

            <p>
              We offer electricity bill payment API for third-party
              integrations, where you could integrate your website with your
              retailers. Our API services are secure with related payment
              gateway and there are no hidden charges for the energy bill.
            </p>

            <p>
              We have a well trained and experienced staff for creating a web
              application and mobile application. We provide customized service
              to our clients as per their requirement. We're adding an
              Increasing Number of support to our APIs such as Mobile Recharge
              API, Travel Booking API, (Flight Booking API, Bus Ticket Booking
              API, Hotel Booking API), and Money Transport API.
            </p>

            <p>
              Among our versatile APIs is the electricity and gas bill APIs
              because they may connect with a large selection of service
              providers all around the country. Forget about the times when you
              needed to stand in long queues to receive your invoices done, as
              you can now do your invoices in your home with the support of our
              API.
            </p>
          </div>
        </div>

        <div className="col-md-4 col-12  d-flex align-items-center justify-content-center">
          <img
            className="img-fluid rounded sideimg"
            src={require("../../../../../../asset/img/apisolution/UTILITY.png")}
          ></img>
        </div>

        <div className="col-12 bottom-p-tag" style={{ marginTop: "-100px" }}>
          <div className="p-5">
            <p>
              The clean interface allows you perform your invoices without
              confusion. We are adding more and more support to our APIs to
              integrate all of the services provided in India. The comparative
              simplicity of using our programs are evident if you have used them
              even once, this can be made possible by constantly upgrading and
              upgrading the APIS to suit your requirements.
            </p>

            <p>
              API of Paype Recharge differs a good deal in every state while
              being in comparison to other service provider's sources. You can
              anticipate the widest area of internet Payment API Services,
              understanding that too with great simplicity to both our client
              group and company client. We've focused constantly on creating our
              B2B and B2C clients get fully committed towards our services.
            </p>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
