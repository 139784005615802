import { Dashboardhead } from "../Dashboardhead";
import "./Api.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
export function DMTAPI() {
  return (
    <div className="bg-light apisolutionhead">
      <Dashboardhead />

      <div className="row mx-0 bg-white">
        <div className="col-md-8 col-12 top-p-tag">
          <div className="p-5">
            <h2 className="text-center">DMT API</h2>

            <p>
              Domestic Money Transfer works precisely the same as how Mobile
              Recharge works, the only difference is that rather than the mobile
              number you need to accumulate the beneficiary bank account number,
              IFSC Code and the amount which has to be transferred out of the
              walk-in clients. Complete your transaction by filling your web
              form. The beneficiary account will be credited the sum within 5-10
              seconds.
            </p>
            <p>
              Paype Recharge is one of India's biggest money remittance and IMPS
              processors. You can now easily leverage Paype's powerful
              partnerships and robust technology backend. Provide your network,
              retailers, and customers the capability to instantly transfer
              money to any bank accounts nationwide. Domestic Money Transfer API
              is a great way to generate extra revenue for site owners, SMEs and
              some other organizations. Intelligent payment routing for minimal
              cost and maximum profit.
            </p>
            <p>
              APIs are used by a lot of banks all over the world. They're a very
              significant part of the banking system. They may be used to get
              data for both internal and external usage. They're also used to
              collect and collate data. APIs are also employed by global
              organizations such as the World Bank and others who have
              substantial datasets with customers in various locations all
              around the globe who should access it.
            </p>
          </div>
        </div>

        <div className="col-md-4 col-12  d-flex align-items-center justify-content-center">
          <img
            className="img-fluid rounded sideimg"
            src={require("../../../../../../asset/img/apisolution/dmt.png")}
          ></img>
        </div>

        <div className="col-12 bottom-p-tag" style={{ marginTop: "-100px" }}>
          <div className="p-5">
            <p>
              Our platform supports NEFT & IMPS transfer modes and the transfer
              mode is automatically switched by the machine. The platform is
              powered by National Transaction Corporation of India (NPCI) who
              hastens the IMPS transactions in India on a real-time basis 24 x 7
              x 365 NEFT: National Electronic Fund Transfer under RBI which
              settles NEFT trades in India as batch shrewd and operates only on
              working day and hours of RBI
            </p>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
