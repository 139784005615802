import { Dashboardhead } from "../Dashboardhead";
import "./Api.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
export function Dthapi() {
  return (
    <div className="bg-light apisolutionhead">
      <Dashboardhead />

      <div className="row mx-0 bg-white">
        <div className="col-md-8 col-12 top-p-tag">
          <div className="p-5">
            <h2 className="text-center">DTH RECHARGE API</h2>
            <p>
              Together with the consumer base for telecom subscribers growing
              considerably online mobile recharge is becoming a favorite notion
              day-by-day. Due to hectic working schedules and lack of time
              online recharging has proven to be a blessing for its mobile/DTH
              users. To benefit from the growing business we provide DTH API
              support to our agents/distributors/state partners. Our API’s among
              the very best mobile/DTH recharge API options available from the
              present day in recharging alternatives arena.
            </p>

            <p>
              In Paype Recharge, we supply automatic recharge solutions for any
              sort of prepaid mobile and DTH recharge. We supply for single sim
              recharge software that is innovatory in the mobile industry. It
              reduces your operative cost and removes hassles of buying and
              maintaining multiple mobile phones and additionally maintaining
              balance from different operators. Our software helps you to
              simplify all of your recharge efforts.
            </p>
          </div>
        </div>

        <div className="col-md-4 col-12  d-flex align-items-center justify-content-center">
          <img
            className="img-fluid rounded sideimg"
            src={require("../../../../../../asset/img/apisolution/DTH.png")}
          ></img>
        </div>

        <div className="col-12 bottom-p-tag" style={{ marginTop: "-100px" }}>
          <div className="p-5">
            <p>
              Our API solution provides an efficient, easy and flexible way to
              integrate with website or application. We use the most appropriate
              APIs to handle all the DTH recharges. This has made our recharge
              portal sites to recharge to almost all DTH services provided in
              India.
            </p>

            <p>
              This automatic mobile recharge system and automated DTH recharge
              system may also be customized and integrated with the website. We
              use the most compatible APIs to handle all the DTH recharges. This
              has made our recharge portal sites to recharge to virtually all
              DTH services provided in India.
            </p>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
