import { Dashboardhead } from "../Dashboardhead";
import "./Api.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
export function Aepsapi() {
    return (

        <div className="bg-light apisolutionhead">
            <Dashboardhead />

            <div className="row mx-0 bg-white">

                <div className="col-md-8 col-12 top-p-tag">
                    <div className="p-5">
                        <h2 className="text-center" >AEPS API</h2>
                        <p>Our Aahdaar enables payment systems will allow our customers to make their payments through their Aadhaar cards. This payment method is extremely convenient because by using this payment method, you will be able to make your payment anywhere you want to and at any time. You can also go completely cashless. </p>
                        <p>The AEPS payment API will allow you to carry out all your banking transactions easily even if you do not have a bank in your area. Our AEPS services include cash deposits, balance inquiries, cash withdrawals, and money transfers. In this method, you can use your Aadhaar card to access your bank account.</p>
                        <p> Our AEPS payment api is easy to use. Anyone can use the API with complete ease. It is also a highly secure payment transaction method.</p>
                    </div>
                </div>


                <div className="col-md-4 col-12  d-flex align-items-center justify-content-center">
                    <img className="img-fluid rounded sideimg" src={require('../../../../../../asset/img/apisolution/AEPS.png')}></img>
                </div>

                <div className="col-12 bottom-p-tag" style={{ marginTop: '-100px' }}>
                    <div className="p-5">
                        <p>You can use the same api for multiple banks. Anyone will be able to access their bank accounts linked with their Aadhaar cards through our AEPS payment API. You will also be able to carry out your transactions under all government schemes. Our team of highly experienced professionals will provide you with the best possible AEPS services. You can also avail of our services at a very affordable and reasonable price.
                        </p>

                    </div>
                </div>

            </div>

            <Dashboardfooter />

        </div>
    );
}