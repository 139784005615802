import { Dashboardhead } from "../Dashboardhead";
import "../../dashboardhead/commerical-loans/Commerical.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
// import { Security } from "@material-ui/icons";
// import { GiReceiveMoney } from "@react-icons/all-files/fa/FaBeer";
// import { FaBeer } from 'react-icons/all';
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { HiDocumentSearch } from "react-icons/hi";
import { TbChartInfographic } from "react-icons/tb";

function Businesscard1(props: any) {
  return (
    <div className="row mx-0 pt-5">
      <div className="col-12 col-xl-3">
        <img className="img-fluid " src={props.businessloanimg}></img>
      </div>

      <div className="col-12 col-xl-5 businessloan-content ">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}
function Businesscard2(props: any) {
  return (
    <div className="row mx-0 justify-content-end pt-3">
      <div className="col-12 col-xl-3">
        <img className="img-fluid" src={props.businessloanimg}></img>
      </div>
      <div className="col-12 col-xl-5 businessloan-content">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}

function Fourcard(props: any) {
  return (
    <div className="col-xl-6 col-12 fourcardmain">
      <i className="fourcardicon">{props.fourcardicon}</i>
      <h6 className="fourcardhead">{props.fourcardhead}</h6>
      <p className="fourdcardcontent">{props.fourdcardcontent}</p>
    </div>
  );
}

export function Msme() {
  return (
    <div>
      <Dashboardhead />
      <h1 className="col-12 businessloan-head p-2">𝐌𝐒𝐌𝐄</h1>

      <div className="container businessloan-body  p-0">
        <Businesscard1
          businessloanp1={"MSME Loan Benifit"}
          businessloanp2={
            "Avail collateral-free business loans and grow your business Get up to ₹30 Lacs at low interest rates starting from 11.75%*"
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/msme/MSME LOAN 1.png")}
        />

        <Businesscard2
          businessloanp1={"Avail business loans at low interest rates"}
          businessloanp2={
            "Get an instantly approved term loan of up to 30 Lakhs at interest rates starting from 11.75% for a tenure of 12 to 60 months. Delay your growth dreams no more, it’s your time to shine!"
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/msme/MSME LOAN 2.png")}
        />
        <Businesscard1
          businessloanp1={"Manage your loan on your own terms"}
          businessloanp2={
            "Zero pre-closure charges and a completely digital process ensure that you are able to avail a business loan in as simplified a way as possible. Business loans are a lot better with Open!"
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/msme/MSME LOAN 3.png")}
        />

        <Businesscard2
          businessloanp1={"Choose from a range of loan options"}
          businessloanp2={
            "Give yourself the Open advantage and choose what suits you best. Apart from easy term loans, we also offer Founder One Card, with a limit of up to 10L and zero annual membership fee, and revenue-based funding (coming soon)"
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/msme/MSME LOAN 4.png")}
        />
      </div>

      <div className="container-fluid bg-light mt-5 ">
        <div className="container pt-3 ">
          <h1 className="col-12 text-center " style={{ letterSpacing: "5px" }}>
            𝐖𝐡𝐲 𝐂𝐡𝐨𝐨𝐬𝐞 𝐏𝐚𝐲𝐩𝐞 ?
          </h1>
          <div className="row ">
            <div className=" col-xl-8 col-12">
              <div className="row mx-0">
                <Fourcard
                  fourcardicon={<GiTakeMyMoney />}
                  fourcardhead={"Collateral-Free Working Capital"}
                  fourdcardcontent={
                    "Get working capital loan arranged and solve your working capital issues using your unpaid invoices via bill discounting in just 24-72 hours*"
                  }
                />

                <Fourcard
                  fourcardicon={<HiDocumentSearch />}
                  fourcardhead={"Shorter Cash Cycles"}
                  fourdcardcontent={
                    "Receive timely access to working capital loan via invoice discounting and speed up your cash cycles for business success"
                  }
                />
                <Fourcard
                  fourcardicon={<GiReceiveMoney />}
                  fourcardhead={"Off-Balance-Sheet"}
                  fourdcardcontent={
                    "Paype offers collateral-free invoice discounting services which helps SMEs like yours gain access to working capital loan that doesn’t impact your balance sheet"
                  }
                />

                <Fourcard
                  fourcardicon={<TbChartInfographic />}
                  fourcardhead={"Business Growth"}
                  fourdcardcontent={
                    "Say hello to business growth and expansion with timely access to money"
                  }
                />
              </div>
            </div>

            <div
              className="col-xl-4 col-12 justify-content-center align-items-center d-flex  p-0"
              style={{ flexDirection: "column" }}
            >
              <div className="col-12 ">
                <img
                  className="img-fluid"
                  src={require("../../../../../../asset/img/commerical img/e-invoicing.png")}
                ></img>
              </div>
              <p className="col-12 mt-3 text-center fs-5 fw-bold">
                All Capital Loan Simplified Through Bill Discounting
              </p>
              <div className="col-12  text-center">
                {/* <button className="bg-info fw-bold" type="button" style={{ borderRadius: '12px', border: 'none', width: '130px', padding: '9px' }}>APPLY NOW</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
