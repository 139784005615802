import { Dashboardhead } from "../Dashboardhead";
import "./Api.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
export function Rechargeapi() {
  return (
    <div className="bg-light apisolutionhead">
      <Dashboardhead />

      <div className="row mx-0 bg-white">
        <div className="col-md-8 col-12 top-p-tag">
          <div className="p-5">
            <h2 className="text-center">RECHARGE API</h2>
            <p>
              Paype Recharge Provides an easy and quick API Solution for Mobile
              Recharge. We support all DTH, mobile and information card
              recharges in single API. So Data Card recharge, DTH recharge, and
              all mobile recharge will operate with a single Mobile recharge
              API. With our enterprise solution you can get the free services of
              Recharge API integration
            </p>
            <p>
              We provide master Mobile Recharge API Development solution in
              India. Any company can start its own API business in India if any
              API development was required by any mobile recharge company using
              a master API. Multiple API's can be integrated into system and API
              administrator can switch these API between operators.
            </p>
            <p>
              You can get various kinds of DTH Recharge bundles. We offer the
              DTH Recharge solutions offered in the current day. We make your
              DTH Recharge options valuable, in addition to extremely economical
              and at the same time making no compromises on efficacy. We put all
              efforts for introducing the latest technological trends which
              makes our DTH Recharge solutions above level. Our DTH Recharge
              solutions offer you basically two types of DTH Recharge choices;
              one is termed as Basic DTH Recharge solutions and the other is the
              Company DTH Recharge solutions.
            </p>
          </div>
        </div>

        <div className="col-md-4 col-12  d-flex align-items-center justify-content-center">
          <img
            className="img-fluid rounded sideimg"
            src={require("../../../../../../asset/img/apisolution/rechargeapi.png")}
          ></img>
        </div>

        <div className="col-12 bottom-p-tag" style={{ marginTop: "-100px" }}>
          <div className="p-5">
            <p>
              We always keep in mind the client needs and budget. So, we provide
              all type of API development solution. It includes different API
              solution like DTH, Data Card, mobile recharge, Gas, electricity,
              postpaid and insurance etc. Our API solution help you a lot to
              make your own recharge portal for mobile recharge, white label
              application, and B2B and B2C recharge software etc.
            </p>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
