import { Dashboardhead } from "../Dashboardhead";
import "./Api.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
export function Flightbookingapi() {
  return (
    <div className="bg-light apisolutionhead">
      <Dashboardhead />

      <div className="row mx-0 bg-white">
        <div className="col-md-8 col-12 top-p-tag">
          <div className="p-5">
            <h2 className="text-center">FLIGHT BOOKING API</h2>

            <p>
              Our Quick, Easy and Flexible Travel APIs directly link you to all
              of the stock need to build a state-of-the-art travel website or
              app. Your customers get from wherever they are on the planet,
              fantastic deals on our flights API.
            </p>

            <p>
              Paype Recharge offering Travel & Hotel booking application for
              hotel & tickets booking online for all the travel routes & hotels
              throughout the world. Now it is easy for the agents to reserve
              tickets according to the client's requirements. With the greatest
              tour & hotel operators and portal offering customers a wide
              assortment of choices all in 1 location, it is difficult for the
              smaller travel agents to compete.
            </p>

            <p>
              Our Flight API has been designed to provide you with maximum
              flexibility at minimum cost, both now and in the future. With
              Paype Flight API, you may be locked into application systems that
              are costly.
            </p>
          </div>
        </div>

        <div className="col-md-4 col-12  d-flex align-items-center justify-content-center">
          <img
            className="img-fluid rounded sideimg"
            src={require("../../../../../../asset/img/apisolution/FLIGHT.png")}
          ></img>
        </div>

        <div className="col-12 bottom-p-tag" style={{ marginTop: "-100px" }}>
          <div className="p-5">
            <p>
              Flight Booking Engine is technical in booking flights on the
              internet with very finest flights available. By incorporating this
              tourism and travel API with your software solution, you as a
              travel service supplier can offer difficult to conquer travel
              related solutions to your prospective customers. It helps in the
              tourism and travel industry to help get the airline booking done
              readily.
            </p>

            <p>
              This booking engine helps the client to book flights, holiday
              packages, resorts, insurance, and also other services online. Our
              flight booking API is your most favored as our GDS integration is
              an entire package focusing on low-cost air carriers. We give the
              very best fares out.
            </p>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
