import { Dashboardhead } from "../Dashboardhead";
import "../../dashboardhead/commerical-loans/Commerical.styles.css";
import { Dashboardfooter } from "../../dashboradfooter/Dashboardfooter";
// import { Security } from "@material-ui/icons";
// import { GiReceiveMoney } from "@react-icons/all-files/fa/FaBeer";
// import { FaBeer } from 'react-icons/all';
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { HiDocumentSearch } from "react-icons/hi";
import { TbChartInfographic } from "react-icons/tb";

function Businesscard1(props: any) {
  return (
    <div className="row mx-0 pt-5">
      <div className="col-12 col-xl-3">
        <img className="img-fluid " src={props.businessloanimg}></img>
      </div>

      <div className="col-12 col-xl-5 businessloan-content ">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}
function Businesscard2(props: any) {
  return (
    <div className="row mx-0 justify-content-end pt-3">
      <div className="col-12 col-xl-3">
        <img className="img-fluid" src={props.businessloanimg}></img>
      </div>
      <div className="col-12 col-xl-5 businessloan-content">
        <p className="business-sub-head">{props.businessloanp1}</p>
        <p>{props.businessloanp2}</p>
      </div>
    </div>
  );
}

function Fourcard(props: any) {
  return (
    <div className="col-xl-6 col-12 fourcardmain">
      <i className="fourcardicon">{props.fourcardicon}</i>
      <h6 className="fourcardhead">{props.fourcardhead}</h6>
      <p className="fourdcardcontent">{props.fourdcardcontent}</p>
    </div>
  );
}

export function Womensloan() {
  return (
    <div>
      <Dashboardhead />
      <h1 className="col-12 businessloan-head p-2">𝐁𝐔𝐒𝐈𝐍𝐄𝐒𝐒 𝐋𝐎𝐀𝐍 𝐅𝐎𝐑 𝐖𝐎𝐌𝐄𝐍𝐒</h1>

      <div className="container businessloan-body  p-0">
        <Businesscard1
          businessloanp1={"An online business loan for women"}
          businessloanp2={
            "Paype business loan application process is totally online, which makes it faster than applying for a loan with a bank, and also makes it totally unbiased when it comes to approvals. The online loan approval process also enables us to offer same-day loan approvals."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/womensloan/womensloan1.png")}
        />

        <Businesscard2
          businessloanp1={"Collateral-free business loans for women"}
          businessloanp2={
            "Paype business loans for women do not require any kind of security and thus, give women entrepreneurs a veritable financial vehicle to grow their enterprise."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/womensloan/womensloan2.png")}
        />
        <Businesscard1
          businessloanp1={"Reduce cash-flow risks"}
          businessloanp2={
            "Paype business loans also allow women entrepreneurs to reduce risk associated with fluctuations in cash-flow. This is especially true for relatively young SMEs and startup ventures."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/womensloan/womensloan3.png")}
        />
        <Businesscard2
          businessloanp1={"Collateral-free Working Capital"}
          businessloanp2={
            "Save your assets and get access to working capital loan to solve your working capital needs only using an unpaid invoice as a pseudo-collateral."
          }
          businessloanimg={require("../../../../../../asset/img/commerical img/womensloan/womensloan4.png")}
        />
      </div>

      <div className="container-fluid bg-light mt-5">
        <div className="container pt-3">
          <h1 className="col-12 text-center" style={{ letterSpacing: "5px" }}>
            𝐖𝐡𝐲 𝐂𝐡𝐨𝐨𝐬𝐞 𝐏𝐚𝐲𝐩𝐞 ?
          </h1>
          <div className="row">
            <div className="col-xl-8 col-12">
              <div className="row mx-0">
                <Fourcard
                  fourcardicon={<GiTakeMyMoney />}
                  fourcardhead={"Collateral-Free Working Capital"}
                  fourdcardcontent={
                    "Get working capital loan arranged and solve your working capital issues using your unpaid invoices via bill discounting in just 24-72 hours*"
                  }
                />

                <Fourcard
                  fourcardicon={<HiDocumentSearch />}
                  fourcardhead={"Shorter Cash Cycles"}
                  fourdcardcontent={
                    "Receive timely access to working capital loan via invoice discounting and speed up your cash cycles for business success"
                  }
                />
                <Fourcard
                  fourcardicon={<GiReceiveMoney />}
                  fourcardhead={"Off-Balance-Sheet"}
                  fourdcardcontent={
                    "Paype offers collateral-free invoice discounting services which helps SMEs like yours gain access to working capital loan that doesn’t impact your balance sheet"
                  }
                />

                <Fourcard
                  fourcardicon={<TbChartInfographic />}
                  fourcardhead={"Business Growth"}
                  fourdcardcontent={
                    "Say hello to business growth and expansion with timely access to money"
                  }
                />
              </div>
            </div>

            <div
              className="col-xl-4 col-12 justify-content-center align-items-center d-flex  p-0"
              style={{ flexDirection: "column" }}
            >
              <div className="col-12 ">
                <img
                  className="img-fluid"
                  src={require("../../../../../../asset/img/commerical img/e-invoicing.png")}
                ></img>
              </div>
              <p className="col-12 mt-3 text-center fs-5 fw-bold">
                All Capital Loan Simplified Through Bill Discounting
              </p>
              <div className="col-12  text-center">
                {/* <button className="bg-info fw-bold" type="button" style={{ borderRadius: '12px', border: 'none', width: '130px', padding: '9px' }}>APPLY NOW</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashboardfooter />
    </div>
  );
}
