import "./Paypeservices.styles.css";
import { Dashboardfooter } from "../dashboradfooter/Dashboardfooter";
import { Dashboardhead } from "../dashboardhead/Dashboardhead";
import { Link } from "react-router-dom";
export function Paypeservices() {
  return (
    <div className="container-fluid mains">
      <Dashboardhead />
      <div className="row">
        <div className=" col-sm-12 servicesimghead px-0">
          <img
            src={require("../../../../../asset/img/services/Serviceus.png")}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>

      <div className="col-12 fourservice p-2 bg-white">
        <div className="row fourservicerow justify-content-evenly">
          <div className="col-6 col-lg-2 d-flex justify-content-center">
            <a href="https://www.kotaksecurities.com/landing-page/Paype-technologies-private-limited-one/">
              <div className="  d-flex justify-content-center align-items-center">
                <img
                  src={require("../../../../../asset/img/services/d.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>Demat Account</p>
            </a>
          </div>

          <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://www.kotaksecurities.com/landing-page/Paype-technologies-private-limited-one/">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={require("../../../../../asset/img/services/pngegg.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>Mutual Funds</p>
            </a>
          </div>

          {/* <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://www.kotaksecurities.com/landing-page/Paype-technologies-private-limited-one/">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={require('../../../../../asset/img/services/fixed2.png')}
                  alt="Demat Acount"
                />
              </div>
              <p>Fixed Deposit</p>
            </a>
          </div> */}

          {/* <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://www.kotaksecurities.com/landing-page/Paype-technologies-private-limited-one/">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={require('../../../../../asset/img/services/gold.png')}
                  alt="Demat Acount"
                />
              </div>
              <p>Gold Investment</p>
            </a>
          </div> */}

          <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://pmfby.gov.in/">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={require("../../../../../asset/img/services/pmfby1.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>PMFBY</p>
            </a>
          </div>
          <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://www.tnpds.gov.in/">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  style={{ height: "110px", width: "110px" }}
                  src={require("../../../../../asset/img/services/tnpds.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>TNPDS </p>
            </a>
          </div>
          <div className="col-6 col-lg-2 d-flex justify-content-center">
            <a href="https://www.passportindia.gov.in/AppOnlineProject/welcomeLink">
              <div className="  d-flex justify-content-center align-items-center">
                <img
                  style={{ height: "120px", width: "100px" }}
                  src={require("../../../../../asset/img/services/passport.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>Passport Seva</p>
            </a>
          </div>
        </div>

        <div className="row fourservicerow justify-content-evenly">
          <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://www.nvsp.in/">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={require("../../../../../asset/img/services/voter.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>Voter ID</p>
            </a>
          </div>

          <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://sarathi.parivahan.gov.in/sarathiservice/stateSelection.do">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  style={{ height: "130px", width: "150px" }}
                  src={require("../../../../../asset/img/services/drive.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>Driving License</p>
            </a>
          </div>

          <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://www.netc.org.in/request-for-netc-fastag">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={require("../../../../../asset/img/services/netc.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>NETC Fastag</p>
            </a>
          </div>

          <div className="col-6 col-lg-2 d-flex justify-content-center ">
            <a href="https://echallan.parivahan.gov.in/index/accused-challan">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  style={{ height: "100px", width: "190px" }}
                  src={require("../../../../../asset/img/services/echallan.png")}
                  alt="Demat Acount"
                />
              </div>
              <p>Police Challan</p>
            </a>
          </div>

          {/* <div className="col-6 col-lg-2 d-flex justify-content-center "> */}
          {/* <a href="#">
                            <div className=" d-flex justify-content-center align-items-center">
                                <img src={require('../../../../../asset/img/services/gold.png')} alt="Demat Acount" />
                            </div>
                            <p>Gold Investment</p>
                        </a> */}
          {/* </div> */}
        </div>
      </div>

      <div className="mt-5 col-sm-12 Paypeservices mx-0 px-0">
        <div className="row mx-0 px-0">
          <div className="col-sm-7">
            <div className="row servicescontent">
              <div className=" col-sm-5">
                <img
                  src={require("../../../../../asset/img/services/Rechargeservices.png")}
                />
                <h4>Domestic Money Transfer</h4>
                <p>
                  Direct Money Transfer (DMT) is a unique product that can be
                  used to send money instantly to any Bank's account holder
                  within India.
                </p>
              </div>
              {/* <div className=" col-sm-5 ">
                <img
                  src={require('../../../../../asset/img/services/Domestic Money Transfer.png')}
                />

                <h4>Instant Money Transfer</h4>
                <p>
                  “Single Page Remittance App” most user friendly platform where
                  money will move faster with OTP, IFSC and Bene adding.
                </p>
                <p className="fw-bold fs-5" style={{ marginTop: '-10px' }}>
                  24 × 7 × 365 days
                </p>
                <p className="fw-bold fs-5" style={{ marginTop: '-10px' }}>
                  ALL BANKS
                </p>
              </div> */}

              <div className=" col-sm-5">
                <img
                  src={require("../../../../../asset/img/services/Micro ATM.png")}
                />
                <h4>Micro ATM</h4>
                <p>
                  Accept Payments or Withdraw on Your Smartphone/Tablet through
                  our mATM Solution. Credit/Debit Card Accepted, Real-Time
                  Settlement.
                </p>
              </div>
              <div className=" col-sm-5">
                <img
                  src={require("../../../../../asset/img/services/Aadhaar.png")}
                />
                <h4>Aadhar Enabled Payment System</h4>
                <p>
                  A bank led model which allows online interoperable financial
                  transactions like Cash Deposit/Withdraw & Mini Statement
                  Enquiry through Aadhar.
                </p>
              </div>
              <div className=" col-sm-5">
                <img
                  src={require("../../../../../asset/img/dashboard/case-management.png")}
                />
                <h4>Cash Management Services</h4>
                <p>
                  Cash management services include managing incoming money from
                  customers with receivable solutions and at Point-of-Sale
                  terminals.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-5 aboutcontent">
            <div>
              <h3 className="col-sm-10 fw-bold" style={{ color: "#06848d" }}>
                Explore The Services We Offer For You
              </h3>
            </div>
            <div>
              <p className="col-sm-10">
                Financial Inclusion is our mission and we believe that every
                person should have access to affordable financial services, no
                matter who they are or where in the world they live – which is
                why Paype has made it one of our top priorities as a company.
                The first step in helping people transform their lives by
                providing them with opportunities beyond what money can buy
                starts here: Core Banking Partners and Corporate Partners. We
                are constantly working on finding new ways to make your life
                convenient. We have exciting offers for you that will save money
                while earning more benefits.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Dashboardfooter />
    </div>
  );
}
